import sendEmail from './sendEmail';

interface EmailJsInterface {
  sendEmail:Function
}

const EmailJSService:EmailJsInterface = { sendEmail };

// eslint-disable-next-line import/prefer-default-export
export { EmailJSService };
