import React, { useId } from 'react';
// motion
import { motion, AnimatePresence } from 'framer-motion';
// models
import { MotionProps } from '../../../models/Motion/MotionProps';

function TransitionOpacity({ children, isVisible }:MotionProps):JSX.Element {
  const id: string = useId();
  return (
    <AnimatePresence>
      {isVisible && (
        <motion.div
          key={id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </motion.div>
      )}
    </AnimatePresence>
  );
}
export default TransitionOpacity;
