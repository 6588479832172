import styled, { StyledComponent } from 'styled-components';
import stylesFonts from '../../../styles/fonts';
import colors from '../../../styles/colors';
import device from '../../../styles/deviceSizeScreen';

const CheckBoxContainer: StyledComponent<'div', any> = styled.div`

    display: grid;
    grid-template-columns: repeat(auto-fit,minmax(300px,2fr));
    width:100%;
    height: auto;

    p{
        border: 1.5px solid #C0C0C0;
        margin: 20px 5px;
        padding:30px 0px 30px 55px;
        width:100vw;
    }

    .checkbox_icon{
        margin:0px 20px;
        width:30px;
        height:30px;
        position:absolute;
        top:45px;
    }

    input:checked + p{
        border: 1.5px solid ${colors.blueOne};  
    }

    p:hover{
        cursor: pointer;
        border: 1.5px solid ${colors.blueOne};  
    }

    label{
        display: flex;
        align-items:center;
        color: ${colors.blueOne};
        font-family:  ${stylesFonts.regularText};
        font-size:2.3vh;
        position:relative;
    }

    input{
        -webkit-appearance: none;
    }

  
    @media ${device.desktop} {
    p{
        width:18vw;
    }

    .checkbox_icon{
        top:45px;
    }

    }

    @media ${device.desktopM} {
        grid-template-columns: repeat(auto-fit,minmax(400px,2fr));
        p{
            width:19vw;
        }
        .checkbox_icon{
            top:55px;
        }
    }

    @media ${device.desktopL} {
        grid-template-columns: repeat(auto-fit,minmax(500px,2fr));
        p{
            width:20vw;
        }

    }

    @media ${device.laptopL} {
        grid-template-columns: repeat(auto-fit,minmax(250px,2fr));
        p{
            width:15vw;
        }
    }

    @media ${device.laptop} {
        grid-template-columns: repeat(auto-fit,minmax(180px,1fr));

        label{
            font-size:1.3vh;
        }

        p{
            width:100vw;
            
        }

        .checkbox_icon{
            margin:0px 10px;
            width:30px;
            height:30px;
            left: 20px;
        }

    }

    @media ${device.tablet} {
        
        grid-template-columns: repeat(auto-fit,minmax(250px,1fr));

        label{
            font-size:1.5vh;
        }
        p{
            width:100%;
        }
        
    }

    @media ${device.mobileXl} {

    .checkbox_icon{
        top:45px;
        left:20px;
    }

        label{
            font-size:2.5vh;
        }
    }
`;

export default CheckBoxContainer;
