import React, { useState } from 'react';
// icons
import { FaLinkedinIn } from 'react-icons/fa';
import { GiHamburgerMenu } from 'react-icons/gi';
// router
import { NavLink } from 'react-router-dom';
// styles
import MenuContainer from './styles';
// models
import { NavLinkProps } from '../../models/reactRouter/NavLinkProps';

function MenuResponsive():JSX.Element {
  const [sideBar, setSidebar] = useState<boolean>(false);
  const handleMenu: React.MouseEventHandler<HTMLButtonElement | HTMLUListElement > = () => {
    setSidebar(!sideBar);
  };
  return (
    <MenuContainer>
      <button
        onClick={handleMenu}
        type="button"
        className="btn"
        title="menu"
        aria-label="menu"
        name="menu"
        aria-labelledby="menu"
      >
        <GiHamburgerMenu />
      </button>
      <div className={`sidebar ${sideBar ? 'active' : ''}`}>
        <ul onClick={handleMenu} role="presentation">
          <li className="sidebar__options">
            <NavLink to="/home" className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}>
              HOME
            </NavLink>
          </li>
          <li className="sidebar__options">
            <NavLink
              to="/about_us"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
            >
              ABOUT US
            </NavLink>
          </li>
          <li className="sidebar__options">
            <NavLink
              to="/careers"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
            >
              CAREERS
            </NavLink>
          </li>
          <li className="sidebar__options">
            <NavLink to="/contact" className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}>
              CONTACT US
            </NavLink>
          </li>
          {/*
          <li className="sidebar__options">
            <NavLink
              to="/case_study"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
            >
              CASE STUDY
            </NavLink>
          </li>
        */}
          <li className="sidebar__icons">
            <div className="sidebar__icons__bg__linkedin">
              <div className="sidebar__icons__linkedin">
                <a href="https://www.linkedin.com/company/devstarmx/mycompany/" target="_blank" rel="noopener noreferrer">
                  <FaLinkedinIn />
                </a>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </MenuContainer>
  );
}

export default MenuResponsive;
