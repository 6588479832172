import styled, { StyledComponent } from 'styled-components';
import device from '../../../styles/deviceSizeScreen';

const BeginJourneyContainer: StyledComponent<'div', any> = styled.div`
    display: flex;
    justify-content:center;
    margin-top: 200px;
    width:100%;
    height:100vh;

    img{
        width:300px;
        height:200px;
        padding:10px;
    }

    @media ${device.mobileL} { 
        img{
            width:300px;
            height:200px;
            padding:10px;
        }
}
`;

export default BeginJourneyContainer;
