import styled, { StyledComponent } from 'styled-components';
import colors from '../../styles/colors';
import stylesFonts from '../../styles/fonts';

const ButtonCv: StyledComponent<'button', any> = styled.button`
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.blueOne};
        border-radius: 30px;
        font-family:  ${stylesFonts.regularText};
        font-size: 3hv;
        margin-top: 30px;
        width: 230px;
        height:60px;

        :hover{
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.greenOne};
        }
`;

export default ButtonCv;
