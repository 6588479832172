import React from 'react';
// router
import { Outlet } from 'react-router-dom';
// components
import Header from '../header';
import Footer from '../footer';

function Layout():JSX.Element {
  return (
    <>
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}

export default Layout;
