import React, { useEffect } from 'react';
// router
import { Link } from 'react-router-dom';
// typer effect
import Typewriter from 'typewriter-effect';
// imgs
import img_one from '../../../assets/images/home_page/image_one.webp';
import lap_top from '../../../assets/images/home_page/section_one_lap.webp';
import shape_blue_section_one from '../../../assets/images/home_page/section_one_shape_blue.webp';
// import section_one_smoke from '../../../assets/images/home_page/section_one_smoke.webp';
// import section_one_spaceship from '../../../assets/images/home_page/section_one_spaceship.webp';
import section_one_cards from '../../../assets/images/home_page/section_one_cards.webp';
import img_two from '../../../assets/images/home_page/ilustration_grap.webp';
import img_three from '../../../assets/images/home_page/image_three.webp';
import element_2 from '../../../assets/images/home_page/element_2.webp';
// import element_4 from '../../../assets/images/home_page/element_4.webp';
// styles
import {
  SectionOne, SectionTwo, SectionThree, SectionFour,
} from './styles';
// animations
import ButtonBlue from '../../buttons/buttonBlue';
import BottomUpIntro from '../../animations/bottomUpIntro';
import RightToLeft from '../../animations/transitionRightToLeft';
// import OpacityIntro from '../../animations/opacityIntro';
// import FloatingAnimation from '../../animations/floatingAnimation';
import SetViewOnTheTop from '../../animations/setViewOnTheTop';
// components
import Partners from '../../partners';

function Home():JSX.Element {
  useEffect(() => {
    document.title = 'Devstar | Home';
    return () => {
      document.title = ' ';
    };
  }, []);
  return (
    <div className="home_section">
      <SetViewOnTheTop view="-100px" />
      <SectionOne>
        <div className="home_section__one__text">
          <BottomUpIntro timeDelay={0}>
            <div className="home_section__one__text__animations_container">
              <h1>Welcome to Devstar</h1>
              <h2>Your software engineering implant</h2>
              <p>
                High performance software engineering in North America,
                aligned with your
              </p>
              <Typewriter
                options={{
                  autoStart: true,
                  cursor: '_',
                  loop: true,
                  strings: ['development needs', 'culture', 'security policies', 'time zone'],
                }}
              />
            </div>
          </BottomUpIntro>
          <BottomUpIntro timeDelay={0.7}>
            <div className="home_section__one__text__buttons">
              <Link to="/contact" aria-label="LAUNCH YOUR TEAM">
                <ButtonBlue text="LAUNCH YOUR TEAM" />
              </Link>
              {/* <button type="button" className="home_section__one__text__button_two">
           <Link to="/careers">BECOME A DEVSTAR</Link></button> */}
            </div>
          </BottomUpIntro>
        </div>
        <div className="home_section__one__img">
          <div className="img__person">
            <BottomUpIntro timeDelay={0.3}>
              <img src={img_one} alt="person" className="home_section__one__img__person" />
            </BottomUpIntro>
          </div>
          <div className="img__lap_top">
            <BottomUpIntro timeDelay={0.5}>
              <img src={lap_top} alt="a img of pc" className="home_section__one__img__lap_top" />
            </BottomUpIntro>
          </div>
          <BottomUpIntro timeDelay={0.3}>
            <img src={shape_blue_section_one} alt="shape blue" className="home_section__one__img__shape_blue" />
          </BottomUpIntro>
          <div className="img__spaceship">
            {/* <BottomUpIntro timeDelay={0.6}>
              <FloatingAnimation timeDelay={2}>
                <img src={section_one_spaceship} alt="spaceship"
                className="home_section__one__img__spaceship" />
              </FloatingAnimation>
          </BottomUpIntro> */}
          </div>
          <div className="section_one_cards">
            <RightToLeft timeDelay={1}>
              <img src={section_one_cards} alt="cards" className="home_section__one__img__cards" />
            </RightToLeft>
          </div>
          <div className="img__smoke">
            {/* <OpacityIntro timeDelay={2}>
              <img src={section_one_smoke} alt="smoke" className="home_section__one__img__smoke" />
          </OpacityIntro> */}
          </div>
        </div>
      </SectionOne>
      <Partners />
      <SectionTwo>
        <div className="home_section__two__img">
          <img src={element_2} alt="shape blue" className="shape_blue" />
          <img src={img_two} alt="graph" className="home_section__two__img__graph" />
        </div>
        <div className="home_section__two__text">
          <h3>A giant leap in nearshore IT</h3>
          <p>
            Making sure your software engineering goals are met:
            Consider us your back office in Mexico
          </p>
          <ul>
            <li>Security trusted: Devstar is compliant with laws and regulations in the US</li>
            <li>
              Teams working 100% in your company and time zone
            </li>
            <li>
              We handle HR and Administration hassles
            </li>
            <li>We reinvest in ongoing development, focusing on long term partnerships</li>
            <li>Constant feedback with you and your Devstar team</li>
          </ul>
        </div>
      </SectionTwo>
      <SectionThree>
        <div className="home_section__three__text">
          <h3>One partner, endless capabilities</h3>
          <p>
            <span>Devstar</span>
            {' '}
            is a community of software developers striving for professional excellence.
            We are the trusted partner to companies who want to scale their development
            teams with committed and reliable team-players.
          </p>
          <Link to="/contact" aria-label="LAUNCH YOUR TEAM">
            <ButtonBlue text="LAUNCH YOUR TEAM" />
          </Link>
        </div>
      </SectionThree>
      <SectionFour>
        <div className="home_section__four__text">
          <h3>We are always with you!</h3>
          <p>
            Through
            {'  '}
            <span>
              {'  '}
              smartly managed teams
            </span>
            {'  '}
            <span>
              {'  '}
              and individual expert software engineers
              {'  '}
            </span>
            we specialize
            in end-to-end implementation of
            high performing remote workforce for tech startups, SMBs and enterprises.
          </p>
        </div>
        <div className="home_section__four__img">
          <div className="home_section__four__img__person__container">
            {/* <img src={element_4} alt="space_ship" className="space_ship" /> */}
            <img src={img_three} alt="person" className="home_section__four__img__person" />
          </div>
        </div>
      </SectionFour>
    </div>
  );
}

export default Home;
