import styled, { StyledComponent } from 'styled-components';
import device from '../../styles/deviceSizeScreen';
import colors from '../../styles/colors';
import stylesFonts from '../../styles/fonts';

const MenuContainer: StyledComponent<'div', any> = styled.div`
    display:none;

    button{
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    .sidebar{
        display: flex;
        justify-content: center;
        background-color:#e1f7ff;
        height: 100vh;
        width: 100vw;
        position: fixed;
        top: 100px;
        left: -100%;
        transition: 0.5s;
        z-index:99;

    ul {
        color:${colors.blueOne};
        display:flex;
        flex-direction:column;
        align-items:center;
        font-family:${stylesFonts.regularText};
        font-size:2.vh;
        list-style-type: none;
        margin:0px 20px 0px 0px;
        width:50%;
    }  

    .sidebar__options  {
        padding: 15px 5px ;
    }

    .sidebar__options:hover {
        border-radius: 5px;
        background-color: #D6EAF8
    }

    li button {
        color:${colors.white};
        background-color:${colors.greenOne};
        border: none;
        border-radius: 40px;
        padding:12px;
    }

    li a button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;;
    }

    li button:hover {
        background-color:${colors.greenHover};
    }

    .sidebar__icons{
        display:flex;
        justify-content:space-around;
        flex-direction:row-reverse;
        align-items:center;
        margin-top:20px;
        width:100%;
    }

    .sidebar__icons__bg__search{
        background-color:${colors.white};
        border-radius: 30px;
        display:flex;
        justify-content: center;
        align-items:center;
        margin:1px;
        position:relative;
        width: 75%;
        height: 45px;
    }

    .sidebar__icons__bg__search svg{
        position: absolute;
        left: 0px;
    }

    .sidebar__icons__bg__linkedin{
        background-color: ${colors.white};
        border-radius: 50%;
        display:flex;
        justify-content: center;
        align-items:center;
        width: 45px;
        height: 45px;
    }

    .sidebar__icons__linkedin{
        background-color: ${colors.linkedinColor};
        border-radius: 50%;
        display:flex;
        justify-content: center;
        align-items:center;
        font-size: 15px;
        width: 25px;
        height: 25px;
    }

    .sidebar__icons__linkedin a {
       position: relative;
    }

    .sidebar__icons__linkedin a svg{
        position: absolute;
        top: -8px;
        left: -8px;
        color: ${colors.white};
        width: 15px;
        height: 15px;
    }

    @media ${device.laptopL} { 
        top: 80px;
        .sidebar__icons{
            width:50%;
        }
    }

    @media ${device.laptop} { 
        top: 120px;
        .sidebar__icons{
            width:60%;
        }
    }

    @media ${device.tablet} { 
        top: 100px;
    }

    @media ${device.mobileL} { 
        top:90px;
        .sidebar__icons{
            width:100%;
        }
    }
    }

    .sidebar.active{
        left: 0;
    }
    
    @media ${device.laptopL} { 
        display: block;
        svg{
        color: ${colors.blueOne};
        margin-right: 10px;
        width: 50px;
        height: 25px;
    }
    }
`;

export default MenuContainer;
