import React from 'react';
// img
import in_process from '../../../assets/images/in_process.png';
// styles
import BeginJourneyContainer from './styles';

function BeginJourney():JSX.Element {
  return (
    <BeginJourneyContainer>
      <img src={in_process} alt="" />
    </BeginJourneyContainer>
  );
}

export default BeginJourney;
