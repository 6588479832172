import React from 'react';
// styles
import Button from './styles';
// models
import { ButtonBlueProps } from '../../../models/buttons/ButtonBlueProps';

function ButtonBlue({ text }: ButtonBlueProps):JSX.Element {
  return (
    <Button
      type="button"
      className="btn"
      title={text}
      aria-label={text}
      name={text}
      aria-labelledby={text}
    >
      {text}
    </Button>
  );
}

export default ButtonBlue;
