import styled, { StyledComponent } from 'styled-components';
import colors from '../../../styles/colors';
import device from '../../../styles/deviceSizeScreen';
import stylesFonts from '../../../styles/fonts';
import wave_right from '../../../assets/images/careers/wave_r.webp';
import wave_left from '../../../assets/images/careers/wave_left.webp';

export const Container: StyledComponent<'div', any> = styled.div`

    margin: 100px 20px; 

    .left-150{
        left:150px;
    }

    .left-50px{
        left:50px;
    }

    .top-05{
        top:-50px;
    }

    .top-5{
        top:480px;
    }

    .all_cvs_size{
        width:75%
    }

    .z-index-01{
        z-index:-10;
    }

    .section_one__tittle{
        color: ${colors.greenOne};
        font-size:3.7vh;
        font-family: ${stylesFonts.boldTitles};
        margin-left:20px;
    }

    .section_one__sub_tittles{
        color: ${colors.blueOne};
        font-size:5vh;
        font-family: ${stylesFonts.boldTitles};
        margin-left:20px;
    }
    
    .section_one__paragraph{
        line-height: 1.4;
        font-size:3vh;
        text-align:left;
        margin-left:20px;
    }

    .section_one__ul {
        color:  ${colors.blueOne};
        margin: 0;
        padding-left:25px;
    }

    .section_one__ul li {
        font-size: 2.7vh;
        margin: 5px 15px;
    }

    .section_two{
        margin: 200px 0px 200px 0px;
    }

    .section_two__tittle{
        color: ${colors.greenOne};
        font-size:3.7vh;
        font-family: ${stylesFonts.boldTitles};
        margin:0px 10px 0px 10px;
    }

    .section_two__container{
       display: flex;
       flex-direction: column;
       align-items: center;
       margin-top: 30px;
    }

    .section_two__paragraph{
        color: ${colors.blueOne};
        font-size:3vh;
        font-family: ${stylesFonts.regularText};
        margin:40px 0px 0px 25px;
        text-align:center;
        width:75%;
    }

    .section_two__paragraph span{
        color: ${colors.greenOne};
    }

    .section_three{
        margin-top: 200px;
    }

    .section_three__tittle{
        color: ${colors.greenOne};
        font-size:4vh;
        font-family: ${stylesFonts.boldTitles};
        margin:10px 0px 40px 30px;
        
    }

    .section_three__perks{
        font-size: 2.7vh;
        margin-bottom: 35px;
    }

    .section_three__perks svg{
        color: ${colors.greenOne};
        font-size: 3.7vh;
    }

    .section_three__perks span{
        color:  ${colors.blueOne};
        font-size: 3.7vh;
        margin-bottom: 5px;
    }

    .section_three_person{
        top:-100px;
        right: 0;
    }

    .section_three_shape{
        top:150px;
    }

    .section_four__p_1{
        color: ${colors.greenOne};
        font-size:5.7vh;
        letter-spacing: 1.5px;
        margin: 100px 0px 0px 0px;
    }

    .section_four__p_2{
        color: ${colors.blueOne};
        font-size:8vh;
        letter-spacing: 1.5px;
        margin-top:-20px;
    }

    .section_four__img{
        width:75%;
    }

    @media ${device.desktop} { 
        margin: 150px 0px 500px 0px;
        padding: 0px 40px;

        .top-5{
            top:640px;
    }
    }

    @media ${device.desktopL} { 
        .top-5{
            top:850px;
        }
    }

    @media ${device.laptop} { 
        margin: 250px 30px 400px 20px;

    .section_four__p_1{
        font-size:3.7vh;
    }

    .section_four__p_2{
        font-size:6vh;
        margin-top:-10px
    }

    .section_four__img{
        width:100%;
    }

    }

    @media ${device.mobileXl} { 

        .section_two__paragraph{
            margin:40px 0px 0px 5px;
            width:100%;
        }
    }

    @media ${device.mobileL} { 
        margin: 150px 0px 200px 0px;
        padding: 15px;
 
        .all_cvs_size{
            width:50%;
        }

        .section_one__sub_tittles{
            font-size:3.5vh;
        }

        .section_two{
            margin-top: 100px;
        }

        .section_three{
            margin-top: 100px;
        }
    }
`;

export const HorizontalMenu: StyledComponent<'div', any> = styled.div`
    display: flex;
    overflow-x: auto;
    height:auto;
    padding-right: 10px;

  &::-webkit-scrollbar {
    display: none;
  }

`;

export const CardRight: StyledComponent<'div', any> = styled.div`

    background-size: cover;
    background-image: url(${wave_right});
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    flex: 1 0 250px;
    margin:10px;
    transition: 0.3s;

    :hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .card__container{
        padding: 2px 16px;
    }

    .card__index{
        padding-right:5px;
        color: ${colors.greenOne};
        font-size:2.7vh;
        font-family: ${stylesFonts.boldTitles};
    }

    .card__tittle{
        color: ${colors.blueOne};
        font-size:2.7vh;
        font-family: ${stylesFonts.boldTitles};
        margin-top:50px;
    }

    .card__paragraph{
        line-height: 2;
        font-size:2.5vh;
        text-align:left;
    }

    .card__paragraph a{
        color: ${colors.blueOne};
    }

    @media ${device.desktop} { 
        width:25vw;
        height:500px;
    }

    @media ${device.laptop} { 
        flex: 1 0 400px;
    }

    @media ${device.mobileXl} { 
        flex: 1 0 300px;
    }

    @media ${device.mobileM} { 
        flex: 1 0 250px;
    }
`;

export const CardLeft: StyledComponent<'div', any> = styled.div`
    
    background-size: cover;
    background-image: url(${wave_left});
    border-radius: 6px;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
    flex: 1 0 250px;
    margin:10px;
    transition: 0.3s;

    :hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    .card__container{
        padding: 2px 16px;
    }

    .card__index{
        padding-right:5px;
        color: ${colors.greenOne};
        font-size:2.7vh;
        font-family: ${stylesFonts.boldTitles};
    }

    .card__tittle{
        color: ${colors.blueOne};
        font-size:2.7vh;
        font-family: ${stylesFonts.boldTitles};
        margin-top:50px;
    }

    .card__paragraph{
        line-height: 2;
        font-size:2.5vh;
        text-align:left;
    }

    @media ${device.desktop} { 
        width:25vw;
        height:500px;
    }

    @media ${device.laptop} { 
        flex: 1 0 400px;
    }

    @media ${device.mobileXl} { 
        flex: 1 0 300px;
    }

    @media ${device.mobileM} { 
        flex: 1 0 250px;
    }
`;
