import React, { useEffect } from 'react';
// styles
import { CaseStudyContainer, Card } from './styles';
// data
import caseStudy from '../../../data/caseStudy';
import LeftToRight from '../../animations/transitionLeftToRight';

function CaseStudy():JSX.Element {
  useEffect(() => {
    document.title = 'Devstar | Case study';
    return () => {
      document.title = ' ';
    };
  }, []);
  return (
    <LeftToRight timeDelay={0.5}>
      <CaseStudyContainer>
        {caseStudy.map((item: any) => (
          <Card key={item.name}>
            <img src={item.img} alt={item.name} />
            <div className="card_text_container">
              <h2>{item.name}</h2>
              <p className="card_text">{item.description}</p>
              <a
                href={item.link}
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Case study sherpa"
                className="btn_card"
              >
                Read more
              </a>
            </div>
          </Card>
        ))}
      </CaseStudyContainer>
    </LeftToRight>

  );
}

export default CaseStudy;
