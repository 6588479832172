const colors: Record<string, string> = {
  black: '#000000',
  blueOne: '#345F81',
  greenHover: '#66cccc',
  greenOne: '#50c4ac',
  linkedinColor: '#0A66C2',
  white: '#ffffff',
};

export default colors;
