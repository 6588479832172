import React, { useEffect } from 'react';
// boostrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Image from 'react-bootstrap/Image';
// icons
import {
  MdHealthAndSafety, MdFamilyRestroom,
  MdLaptopMac, MdThumbUp, MdTrendingUp,
} from 'react-icons/md';
// img
import woman from '../../../assets/images/careers/woman.webp';
import all_cvs from '../../../assets/images/careers/all_cvs_1.webp';
import shape_blue from '../../../assets/images/careers/shape_blue_1.webp';
import devstar_team from '../../../assets/images/careers/devstar_team.webp';
// styles
import {
  Container, CardRight, HorizontalMenu, CardLeft,
} from './styles';
// components
import LoadCvModal from '../../loadCvModal';
// animations
import OpacityIntro from '../../animations/opacityIntro';
import SetViewOnTheTop from '../../animations/setViewOnTheTop';
import LeftToRight from '../../animations/transitionLeftToRight';
import OpacityIntroScroll from '../../animations/opacityIntroScroll';
import LeftToRightScroll from '../../animations/LeftToRightScroll';

function Careers():JSX.Element {
  useEffect(() => {
    document.title = 'Devstar | Careers';
    return () => {
      document.title = ' ';
    };
  }, []);
  return (
    <Container>
      <Row className="mb-5 d-flex flex-column-reverse flex-xl-row justify-content-center section_one overflow-hidden">
        <Col className="position-relative">
          <LeftToRight timeDelay={0.5}>
            <Image src={woman} className="img-fluid w-75" alt="woman" />
            <Image src={all_cvs} className="img-fluid all_cvs_size position-absolute left-150 top-05 z-index-01" alt="cv" />
            <Image src={shape_blue} className="d-none d-xl-block img-fluid w-75 position-absolute left-50px top-5 z-index-01" alt="shape blue" />
          </LeftToRight>
        </Col>
        <Col className="mb-5">
          <OpacityIntro timeDelay={1}>
            <SetViewOnTheTop view="-150px" />
            <h2 className="section_one__tittle">Let’s rewrite the code</h2>
            <h3 className="section_one__sub_tittles">BECOME A DEVSTAR</h3>
            <h4 className="section_one__tittle">Why work at Devstar?</h4>
            <p className="section_one__paragraph">
              We are building a culture in which professional and personal development
              is as important as business growth.
              {' '}
            </p>
            <ul className="section_one__ul">
              <li>
                We all support Devstar’s success; our total commitment is to delight our customers.
              </li>
              <li>
                We are insistent on building working relationships based on honesty
                and transparency,
                in this way we care about the growth of each one of us.
              </li>
              <li>
                There is no inner circle, the entire Devstar team shares its ideas and knowledge.
                We all have a voice and a vote
              </li>
              <li>
                We are all empowered to work autonomously. At Devstar you own the work that directly
                impacts the business, you can learn fast.
              </li>
            </ul>
          </OpacityIntro>
        </Col>
      </Row>
      <div className="section_two">
        <OpacityIntroScroll timeDelay={0.2}>
          <h3 className="section_two__tittle my-5">Our application process is easy</h3>
          <HorizontalMenu>
            <CardRight>
              <div className="card__container">
                <h3 className="card__tittle">
                  {' '}
                  <span className="card__index"> 1.- </span>
                  {' '}
                  Send your information
                </h3>
                <p className="card__paragraph">
                  Apply for one of our open positions
                  {' '}
                  <a href="https://www.linkedin.com/company/devstarmx/jobs/" target="_blank" rel="noopener noreferrer">
                    here!
                  </a>
                  {' '}
                  and someone from our team will contact you.
                </p>
              </div>
            </CardRight>
            <CardLeft>
              <div className="card__container">
                <h3 className="card__tittle">
                  {' '}
                  <span className="card__index"> 2.- </span>
                  {' '}
                  Interview and feedback process
                </h3>
                <p className="card__paragraph">
                  It includes an interview with the managers and/or
                  a technical test, depending on the vacancy.
                </p>
              </div>
            </CardLeft>
            <CardRight>
              <div className="card__container">
                <h3 className="card__tittle">
                  {' '}
                  <span className="card__index"> 3.- </span>
                  {' '}
                  Decision stage
                </h3>
                <p className="card__paragraph">
                  If the application was successful you will be sent an offer.
                  Otherwise you will be sent useful feedback for future interviews.
                </p>
              </div>
            </CardRight>
            <CardLeft>
              <div className="card__container">
                <h3 className="card__tittle">
                  {' '}
                  <span className="card__index"> 4.- </span>
                  {' '}
                  Welcome aboard!
                </h3>
                <p className="card__paragraph">
                  Enjoy the benefits of starting your adventure at Devstar.
                </p>
              </div>
            </CardLeft>
          </HorizontalMenu>
          <div className="section_two__container">
            <p className="section_two__paragraph">
              If you have a passion for building high quality technology
              products and solving challenges
              in the programming world,
              <span>
                {' '}
                Devstar
                {' '}
              </span>
              could be a career-boosting move for you
              {' '}
            </p>
            <LoadCvModal />
          </div>
        </OpacityIntroScroll>
      </div>
      <LeftToRightScroll>
        <Row className="section_three d-flex flex-column flex-xl-row overflow-hidden mb-5">
          <h3 className="section_three__tittle">Benefits of being part of the team...</h3>
          <Col className="px-5">
            <div className="section_three__perks">
              <span>
                {' '}
                <MdHealthAndSafety />
                {' '}
                Health @Devstar
              </span>
              <br />
              Emotional health and personal wellness platform.
            </div>
            <div className="section_three__perks">
              <span>
                <MdFamilyRestroom />
                {' '}
                Family benefits.
              </span>
              <br />
              Access to physicians and psychologists extended to the whole family.
            </div>
            <div className="section_three__perks">
              <span>
                {' '}
                <MdLaptopMac />
                {' '}
                Remote work
              </span>
              <br />
              Work wherever you want.
            </div>
          </Col>
          <Col className="px-5">
            <div className="section_three__perks">
              <span>
                {' '}
                <MdThumbUp />
                {' '}
                Attractive benefits
              </span>
              <br />
              Remuneration and benefits above the law.
            </div>
            <div className="section_three__perks">
              <span>
                {' '}
                <MdTrendingUp />
                {' '}
                Boost your career
              </span>
              <br />
              Performs meaningful work that makes an impact.
            </div>
          </Col>
        </Row>
      </LeftToRightScroll>
      <OpacityIntroScroll timeDelay={0.2}>
        <Row className="d-flex flex-column align-items-center mt-5">
          <Col className="d-flex flex-column align-items-center">
            <p className="section_four__p_1">Let`s grow</p>
            <p className="section_four__p_2">together</p>
          </Col>
          <Col className="d-flex justify-content-center p-5">
            <Image src={devstar_team} className="section_four__img img-fluid rounded" alt="devstar team" />
          </Col>
        </Row>
      </OpacityIntroScroll>
    </Container>
  );
}

export default Careers;
