import styled, { StyledComponent } from 'styled-components';
import bgFooter from '../../assets/images/bg_footer.webp';
import device from '../../styles/deviceSizeScreen';
import colors from '../../styles/colors';
import stylesFonts from '../../styles/fonts';

export const FooterContainer: StyledComponent<'footer', any> = styled.footer`

        background-image: url(${bgFooter});
        height:700px;
        background-size: cover;
        margin-top: -160px;

        @media ${device.laptopL} { 
            background-image: none;
            background-color: ${colors.blueOne};
            width: 100%;
            height:500px;
        }

        @media ${device.desktop} { 
            margin-top: -460px;
        }

        @media ${device.desktopM} {
            height:900px; 
            margin-top: -660px;
        }

        @media ${device.tablet} { 
            height:350px;
        }

        @media ${device.mobileM} { 
            height:300px;
        }

`;

export const FoooterSubContainer: StyledComponent<'div', any> = styled.div`

        position: relative;
        display: flex;
        flex-direction: column;

        img{
            position: absolute;
            top:360px;
            left: 20px;
            width:220px;
            height:auto;
        }

        .footer__contact{
            color: ${colors.white};
            display:flex;
            justify-content:space-between;
            font-family: ${stylesFonts.regularText};
            font-size:2.3vh;
            width: 28%;
            position: absolute;
            top: 460px;
            left: 30px;
        }

        .footer__copyrigh{
            color: ${colors.white};
            font-family: ${stylesFonts.regularText};
            font-size:2.3vh;
            position: absolute;
            top: 520px;
            left: 30px;
        }
        
        @media ${device.desktop} { 

            img{
                top:400px;
                width:220px;
                height:auto;
            }
            
            .footer__contact{
                top: 500px;
                flex-direction:column;
                justify-content: space-between;
            }

            .footer__contact p{
                margin: 10px 0px 5px 0px;
            }

            .footer__copyrigh{
            top: 620px;
        }

        }

        @media ${device.desktopM} {
            img{
                top:550px;
                width:220px;
                height:auto;
            }
            
            .footer__contact{
                top: 650px;
                flex-direction:column;
                justify-content: space-between;
            }

            .footer__contact p{
                margin: 10px 0px 5px 0px;
            }

            .footer__copyrigh{
                top: 780px;
            }
        }

        @media ${device.laptopL} { 
            align-items: center;
            flex-direction: column;
            
            img{
                position: relative;
                top:10px;
                width:180px;
                height:auto;
            }
            .footer__contact{
                position: relative;
                top: 30px;
                width: 32%;
                padding-right: 55px;
            }

            .footer__copyrigh{
                position: relative;
                top: 30px;
                padding-right: 60px;
            }
        }

        @media ${device.laptop} { 
        
        .footer__contact{
            width:40%;
            flex-direction:column;
            align-items:center;
        }

        }

        @media ${device.tablet} { 
        
        .footer__contact{
            font-size:2vh;
            flex-direction:column;
            align-items:center;
            width:70%;
        }

        img{
                width:180px;
                height:auto;
            }

        .footer__copyrigh{
            font-size:2vh;
            position: relative;
            top: 20px;
            padding-right: 20px;
        }
    }
    
`;
