import styled, { StyledComponent } from 'styled-components';
import device from '../../styles/deviceSizeScreen';
import colors from '../../styles/colors';
import bgHeader from '../../assets/images/bg_header.webp';
import stylesFonts from '../../styles/fonts';

export const NavContainer: StyledComponent<'nav', any> = styled.nav`
    background-image: url(${bgHeader});
    width:100%;
    height:300px;
    background-size: cover;
    position: relative;

    @media ${device.desktop} {
        height:550px;
    }

    @media ${device.laptopL} {
        background-color:#e1f7ff;
        background-image:none; 
        height:90px;
        position: fixed;
        z-index: 50;
        width: 100%;
        top: 0;
        right: 0;
    }

    @media ${device.laptop} {
        height:120px;
    }

    @media ${device.tablet} {
        height:100px;
    }

    @media ${device.mobileM} {
        height:90px;
    }
`;

export const HeaderContainer: StyledComponent<'div', any> = styled.div`
    display:flex;
    justify-content:space-between;
    align-items:center;
    padding-top: 15px;

    .active{
        border-bottom: 2px solid ${colors.greenOne};
    }

    img{
        margin-left: 30px;
        width:23vh;
        height:auto;
    }

    @media ${device.laptopL} {
        display: flex;
        align-items:center;
        margin-top: 10px;

        img{
            margin-left: 20px;
            width:18vh;
            height:auto;
        }
    }

    @media ${device.laptop} {
        img{
            width:20vh;
            height: auto;
        }
    }

    @media ${device.tablet} {
        img{
            width:15vh;
            height: auto;
        }
    }

    @media ${device.mobileM} {
        img{
            width:19vh;
            height: auto;
        }
    }
`;

export const UlContainer: StyledComponent<'ul', any> = styled.ul`
   
    color:${colors.blueOne};
    display:flex;
    justify-content:flex-end;
    align-items:center;
    font-family:${stylesFonts.regularText};
    font-size:2.vh;
    list-style-type: none;
    margin:0px 20px 0px 0px;
    width:55%;   

    .header__menu__options  {
        padding: 15px 5px ;
    }

    .header__menu__options:hover {
        border-radius: 5px;
        background-color: #D6EAF8
    }

    li{
        margin: 0px 10px;
    }

    li button {
        color:${colors.white};
        background-color:${colors.greenOne};
        border: none;
        border-radius: 40px;
        padding:12px;
    }

    li button:hover {
        background-color:${colors.greenHover};
    }

    .header__icons{
        display:flex;
        justify-content:space-around;
        align-items:center;
        width:50px;
    }

    .header__icons__bg__search{
        background-color:${colors.white};
        border-radius: 50%;
        display:flex;
        justify-content: center;
        align-items:center;
        width: 45px;
        height: 45px;
    }

    .header__icons__bg__linkedin{
        background-color: ${colors.white};
        border-radius: 50%;
        display:flex;
        justify-content: center;
        align-items:center;
        width: 45px;
        height: 45px;
    }

    .header__icons__linkedin{
        background-color: ${colors.linkedinColor};
        border-radius: 50%;
        color:  ${colors.white};
        display:flex;
        justify-content: center;
        align-items:center;
        font-size: 15px;
        width: 25px;
        height: 25px;
    }

    .header__icons__linkedin a{
        color:  ${colors.white};
        display:flex;
        justify-content: center;
        align-items:center;
    }

    /*@media ${device.desktop} { 
        .header__icons{
            width:200px;
        }
    }*/

    @media ${device.laptopL} { 
        display:none;
    }
`;
