/* eslint-disable jsx-a11y/label-has-associated-control */
import React from 'react';
import CheckBoxContainer from './styles';
// models
import { CheckBoxProps } from '../../../models/inputs/CheckBoxProps';

function CheckBoxInput({
  data, name, setskills, skills,
}
:CheckBoxProps):JSX.Element {
  function setSkill(e:any) {
    const isChecked:any = e.target.checked;
    if (isChecked) {
      setskills((skill:Array<string>) => ([...skill, e.target.value]));
    } else {
      const index:number = skills.indexOf(e.target.value);
      skills.splice(index, 1);
      setskills((skill:Array<string>) => ([...skill]));
    }
  }
  return (
    <CheckBoxContainer>
      {data.map((value: any) => (
        <label key={value.name}>
          <img src={value.img} alt={value.name} className="checkbox_icon" />
          <input type="checkbox" name={name} value={value.name} onChange={setSkill} />
          <p>{value.name}</p>
        </label>
      ))}
    </CheckBoxContainer>
  );
}

export default CheckBoxInput;
