import React, { useId, useRef } from 'react';
// motion
import { motion } from 'framer-motion';
// models
import { MotionProps } from '../../../models/Motion/MotionProps';

function LeftToRightScroll({ children, timeDelay }:MotionProps):JSX.Element {
  const scrollRef:React.MutableRefObject<null> = useRef(null);
  const id: string = useId();
  return (
    <motion.div
      key={id}
      initial={{ opacity: 0, x: -300 }}
      whileInView={{ opacity: 1, x: 0 }}
      viewport={{ margin: '-50px', once: true }}
      transition={{ delay: timeDelay, duration: 1 }}
    >
      <div ref={scrollRef} style={{ position: 'absolute', top: 1 }}>{}</div>
      {children}
    </motion.div>
  );
}
export default LeftToRightScroll;
