import React, { useState } from 'react';
// calendly
import { InlineWidget } from 'react-calendly';
// router
import { Link } from 'react-router-dom';
// styles
import { CalendlyContainer, CalendlyElement } from './styles';
// models
import { CalendlyProps } from '../../models/calendly/CalendlyProps';
// env
import { env } from '../../env';
// components
import ButtonBlue from '../buttons/buttonBlue';

function Calendly({ email, name }: CalendlyProps):JSX.Element {
  const [isScheduled, setIsScheduled] = useState<boolean>(false);

  function setCalendlyEvent(e:any) {
    const path: string = e.origin === 'https://calendly.com' && e.data.event && e.data.event.indexOf('calendly.') === 0;
    if (path) {
      if (e.data.event === 'calendly.event_scheduled') {
        setIsScheduled(!isScheduled);
      }
    }
  }

  window.addEventListener('message', (e:any) => {
    setCalendlyEvent(e);
  });
  return (
    <CalendlyContainer>
      <CalendlyElement>
        <InlineWidget
          url={env.REACT_APP_DEVSTAR_CALENDLY_LINK}
          styles={{
            height: '900px',
          }}
          prefill={{
            email,
            name,
          }}
        />
      </CalendlyElement>
      {isScheduled
        ? (
          <div className="calendly_container_button">
            <Link to="/home">
              <ButtonBlue text="HOME" />
            </Link>
          </div>
        )
        : null}
    </CalendlyContainer>

  );
}

export default Calendly;
