import React, { useId } from 'react';
// motion
import { motion, AnimatePresence } from 'framer-motion';
// models
import { MotionProps } from '../../../models/Motion/MotionProps';

function RightToLeft({ children, timeDelay }:MotionProps):JSX.Element {
  const id: string = useId();
  return (
    <AnimatePresence>
      <motion.div
        key={id}
        initial={{ opacity: 0, x: 300 }}
        animate={{ opacity: 1, x: 0 }}
        exit={{ opacity: 0, x: -300 }}
        transition={{ delay: timeDelay, duration: 1 }}
      >
        {children}
      </motion.div>
    </AnimatePresence>
  );
}
export default RightToLeft;
