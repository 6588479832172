import React, { useEffect } from 'react';
// bootstrap
import {
  Row, Col, Image,
} from 'react-bootstrap';

// Import AliceCarousel
import AliceCarousel from 'react-alice-carousel';
import 'react-alice-carousel/lib/alice-carousel.css';
import { Link } from 'react-router-dom';
// components
import Cards from '../../cards';
// img
import team_devstar from '../../../assets/images/about_us/team_devstar.webp';
import devstar_dev from '../../../assets/images/about_us/devstar_dev.webp';
import devstar_meetwebp from '../../../assets/images/about_us/devstar_meetwebp.webp';
import founders from '../../../assets/images/about_us/founders.webp';
import jp_francisco from '../../../assets/images/about_us/jp_francisco.webp';
import jp_santiago from '../../../assets/images/about_us/jp_santiago.webp';
import office from '../../../assets/images/about_us/office.webp';
import san from '../../../assets/images/about_us/santiago_2.webp';
import team_sherpa_devstar from '../../../assets/images/about_us/team_sherpa_devstar.webp';
import founders_2 from '../../../assets/images/about_us/founders_2.webp';
import francisco_2 from '../../../assets/images/about_us/francisco_2.webp';

// styles
import {
  TitleS, SubTitleM, Paragraph, SectionTwo, CarrouselContainer,
  AboutUsContainer, ImgCarousel, SectionFour, MissionContainer,
} from './styles';
// animations
import LeftToRight from '../../animations/transitionLeftToRight';
// data
import leads from '../../../data/leads';
import SetViewOnTheTop from '../../animations/setViewOnTheTop';

function AboutUs():JSX.Element {
  useEffect(() => {
    document.title = 'Devstar | About us';
    return () => {
      document.title = ' ';
    };
  }, []);

  const items: JSX.Element[] = [
    <ImgCarousel src={devstar_dev} alt="devstar_dev" />,
    <ImgCarousel src={devstar_meetwebp} alt="devstar_meetwebp" />,
    <ImgCarousel src={founders} alt="founders" />,
    <ImgCarousel src={jp_francisco} alt="jp_francisco" />,
    <ImgCarousel src={jp_santiago} alt="jp_santiago" />,
    <ImgCarousel src={office} alt="office" />,
    <ImgCarousel src={san} alt="san" />,
    <ImgCarousel src={team_sherpa_devstar} alt="team_sherpa_devstar" />,
    <ImgCarousel src={founders_2} alt="founders_2" />,
    <ImgCarousel src={francisco_2} alt="francisco_2" />,
  ];

  return (
    <AboutUsContainer>
      <LeftToRight timeDelay={0.5}>
        <Row className="d-flex flex-column-reverse flex-xl-row py-sm-5">
          <SetViewOnTheTop view="-100px" />
          <Col className="p-sm-5 my-sm-5 px-5">
            <Image rounded src={team_devstar} alt="devstar team" className="w-100" />
          </Col>
          <Col className="p-5 my-sm-5 ">
            <TitleS>Our Team</TitleS>
            <SubTitleM>
              Meet the people
              <br />
              behind Devstar
            </SubTitleM>
            <Paragraph className="p-1">
              Our founders saw Devstar as an opportunity to make staffing services
              from Mexico to the US more meaningful, personal and ultimately more productive.
            </Paragraph>
            <Paragraph className="p-1">
              Every member of our team brings industry experience and a deep-seated
              connection to guide our software engineers and our clients towards establishing
              a nearshore partnership that is characterized by
              respect, empathy, and increased efficiency.
            </Paragraph>
          </Col>
        </Row>

        <SectionTwo>
          <div className="sectionTwoTitle">Our Leadership Team</div>
          <Cards data={leads} />
        </SectionTwo>
        <MissionContainer>
          <h3 className="mission_title">
            Our mission
          </h3>
          <p className="mission_text">
            Devstar’s mission is to empower the software engineering community in
            Mexico through high quality tech challenges
          </p>
        </MissionContainer>
        <CarrouselContainer>
          <p className="carrousel_text">Our community</p>
          <AliceCarousel
            items={items}
            responsive={{
              0: { items: 1 },
              1024: { items: 3 },
              768: { items: 2 },
            }}
            autoPlayInterval={3000}
            autoPlayDirection="ltr"
            autoPlay
            disableButtonsControls
            infinite
          />
        </CarrouselContainer>
        <SectionFour>
          <h2 className="sectionFourTitle">Get started</h2>
          <button type="button">
            <Link to="/contact" aria-label="LAUNCH YOUR TEAM"> Learn how Devstar can help you </Link>
          </button>
        </SectionFour>
      </LeftToRight>
    </AboutUsContainer>
  );
}

export default AboutUs;
