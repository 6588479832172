import styled, { StyledComponent } from 'styled-components';
import device from '../../styles/deviceSizeScreen';
import colors from '../../styles/colors';
import stylesFonts from '../../styles/fonts';

export const CardContainer: StyledComponent<'div', any> = styled.div`
    
  display: flex;
  justify-content: space-around;
  padding:0px 40px;
  overflow-x: scroll;
  width: 100%;
  flex-wrap: nowrap;

    .card {
        background-color: ${colors.white};
        border-radius: 6px;
        flex-shrink: 0;
        margin-bottom: 50px;
        width: auto;
        height: auto;
        margin-right: 10px;
    }

    .card_img {
        border-radius: 6px 6px 0px 0px;
        width: 20vw;
        height: auto;
    }

    .card_title {
        color: ${colors.black};
        font-size:2.5vh;
        font-family: ${stylesFonts.boldTitles}; 
        margin: 20px 10px 0px 10px;
    }

    .card_text {
        color: ${colors.black};
        font-size:1.7vh;
        font-family: ${stylesFonts.regularText}; 
        margin: 10px 0px 10px 10px;
    }

    .card_button{
        background: none;
        color: ${colors.greenOne};
        border: none;
        margin: 0px 10px 20px 0px;
        padding: 0 0 0 8px;
        width: 100px;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        font-size:2.0vh;
    }

    .custom_modal_header{
        background-color:${colors.blueOne}
    }

    @media ${device.desktop} { 
        .card_button{
            width: 150px;
            margin: 0px 10px 20px 0px;
            padding-left: 10px;
            text-align:left;
    }
    }
    
    @media ${device.laptopL} { 
        justify-content:space-between;

        .card{
            margin:0px 20px 20px 20px;
        }

        .card_img {
            width: 40vw;
        }
        
        .card_text {
            font-size:1.4vh;
        }

        .card_button{
            width: 150px;
            margin: 0px 10px 20px 0px;
            padding:0 0 0 10px;
            text-align:left;
        }
    }

    @media ${device.tablet} { 
        justify-content:space-between;

        .card{
            margin:0px 20px 20px 10px;
        }

        .card_img {
            width: 70vw;
        }
        
        .card_text {
            font-size:1.4vh;
        }

        .card_button{
            width: 150px;
            margin: 0px 10px 20px 0px;
            padding:0 0 0 10px;
            text-align:left;
        }
    }
`;

export const ModalSubTitle: StyledComponent<'div', any> = styled.div`
        color: ${colors.greenOne};
        font-size:3.9vh;
        font-family: ${stylesFonts.boldTitles};  
        text-align: left;

        @media ${device.desktopM} {
        font-size:3vh;
    }
    
        @media ${device.laptopM} {
        text-align:center;
    }
        
        @media ${device.laptop} {
        text-align:center;
    }
`;

export const ModalDescription: StyledComponent<'div', any> = styled.div`
      color: ${colors.blueOne};
        font-size:1.9vh;
        font-family: ${stylesFonts.boldTitles};  
        text-align: left;

        @media ${device.desktopM} {
        font-size:1.5vh;
    }
    
        @media ${device.laptopM} {
        text-align:center;
    }
        
        @media ${device.laptop} {
        text-align:center;
    }
`;

export const ModalTitle: StyledComponent<'h2', any> = styled.h2`
        color: ${colors.blueOne};
        font-size:3vh;
        font-family: ${stylesFonts.boldTitles}; 
        text-align: left;

        @media ${device.desktopM} {
        font-size:2vh;
    }
    
        @media ${device.laptopM} {
        text-align:center;
    }
`;

export const ModalImage: StyledComponent<'img', any> = styled.img`
        border-radius: 50%;
        max-width: 20vw;
        padding: 30px;
        height: auto;

    @media ${device.laptop} {
        max-width: 50vw;
        text-align:center;
    }

    @media ${device.tablet} {
        max-width: 70vw;
        text-align:center;
    }

    @media ${device.desktopM} {
            max-width: 13vw;
    }
`;

export const ModalParagraph: StyledComponent<'div', any> = styled.div`
        text-align: justify;
`;
