declare global {
  interface Window {
    env: any
  }
}

export type EnvType = {
  REACT_APP_DEVSTAR_CALENDLY_LINK: string,
  REACT_APP_DEVSTAR_EMAILJS_PUBLIC_KEY: string,
  REACT_APP_DEVSTAR_EMAILJS_SERVICE_ID: string,
  REACT_APP_DEVSTAR_EMAILJS_TEMPLATE_ID: string,
  REACT_APP_DEVSTAR_EMAILJS_VERIFY_SERVICE_ID: string,
  REACT_APP_DEVSTAR_EMAILJS_VERIFY_TEMPLATE_ID: string,
  REACT_APP_DEVSTAR_GOOGLE_ANALYTICS_ID: string,
  REACT_APP_DEVSTAR_TEMPLATE_ELFSIGHT: string,
};

export const env:EnvType = { ...process.env, ...window.env };
