import React from 'react';
// router
import { Route, Routes } from 'react-router-dom';
// components
import Layout from '../layout';
// pages
import {
  Careers, Partnerships, Contact, BeginJourney, Home, AboutUs, CaseStudy,
} from '../pages';

function App():JSX.Element {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Layout />}>
          <Route index element={<Home />} />
          <Route path="/home" element={<Home />} />
          <Route path="/careers" element={<Careers />} />
          <Route path="/partnerships" element={<Partnerships />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/journey" element={<BeginJourney />} />
          <Route path="/about_us" element={<AboutUs />} />
          <Route path="/case_study" element={<CaseStudy />} />
        </Route>
      </Routes>
    </div>
  );
}

export default App;
