/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useRef, useState, useEffect } from 'react';
// models
import { FormContactProps } from '../../models/inputs/FormContactProps';
// services
import { EmailJSService } from '../../services/emailJs/index';
// env
import { env } from '../../env';
// components
import ToolTipRequired from '../tooltip/index';
import CheckBoxInput from '../customInputs/checkBoxInput';
import Calendly from '../calendly';
// effects
import TransitionSlider from '../animations/transitionSlider';
import TransitionOpacity from '../animations/transitionOpacity';
// styles
import {
  ContactFormContent, InputsContainer, EmployeesContainer, SkillsContainer, PrivacyPolicy,
} from './styles';
// data
import programingLanguages from '../../data/programingLanguages';

function FormContact({ setStep, step }: FormContactProps):JSX.Element {
  const [CheckBoxValidation, setCheckBoxValidation] = useState<boolean>(false);
  const [radioInputValidation, setRadioInputValidation] = useState<boolean>(false);
  const [inputRadioSelected, setinputRadioSelected] = useState<boolean>(false);
  const [showCalendly, setShowCalendly] = useState<boolean>(false);
  const [calendlyName, setCalendlyName] = useState<string>('');
  const [calendlyEmail, setCalendlyEmail] = useState<string>('');
  const [skills, setskills] = useState<Array<string | any>>([]);
  const form: any = useRef() as React.MutableRefObject<HTMLFormElement>;
  const toolTipref: any = useRef() as React.MutableRefObject<HTMLFormElement>;
  const refStep: any = useRef() as React.MutableRefObject<HTMLDivElement>;
  const email: any = useRef() as React.MutableRefObject<HTMLInputElement>;
  const name :any = useRef() as React.MutableRefObject<HTMLInputElement>;

  const submitForm: any = (e:any) => {
    e.preventDefault();
    if (skills.length < 1) {
      setCheckBoxValidation(true);
    } else if (inputRadioSelected === false) {
      setRadioInputValidation(true);
      toolTipref.current?.scrollIntoView({ behavior: 'smooth' });
    } else {
      EmailJSService.sendEmail(
        env.REACT_APP_DEVSTAR_EMAILJS_SERVICE_ID,
        env.REACT_APP_DEVSTAR_EMAILJS_TEMPLATE_ID,
        form.current,
        env.REACT_APP_DEVSTAR_EMAILJS_PUBLIC_KEY,
      ); // email contac us
      EmailJSService.sendEmail(
        env.REACT_APP_DEVSTAR_EMAILJS_VERIFY_SERVICE_ID,
        env.REACT_APP_DEVSTAR_EMAILJS_VERIFY_TEMPLATE_ID,
        form.current,
        env.REACT_APP_DEVSTAR_EMAILJS_PUBLIC_KEY,
      ); // email verification
      setStep(step + 1);
      refStep.current?.scrollIntoView({ behavior: 'smooth' });
      setCalendlyName(name.current.value);
      setCalendlyEmail(email.current.value);
      setShowCalendly(!showCalendly);
      e.target.reset();
    }
  };
  // eslint-disable-next-line no-console
  console.log(
    env.REACT_APP_DEVSTAR_EMAILJS_SERVICE_ID,
    env.REACT_APP_DEVSTAR_EMAILJS_TEMPLATE_ID,
    env.REACT_APP_DEVSTAR_EMAILJS_VERIFY_SERVICE_ID,
    env.REACT_APP_DEVSTAR_EMAILJS_VERIFY_TEMPLATE_ID,
    env.REACT_APP_DEVSTAR_EMAILJS_PUBLIC_KEY,
  );
  function validationInputRadio(e:any) {
    const isChecked:any = e.target.checked;
    if (isChecked) {
      setinputRadioSelected(true);
      setRadioInputValidation(false);
    }
  }
  useEffect(() => {
    if (CheckBoxValidation) {
      setCheckBoxValidation(false);
    }
  }, [skills]);
  return (
    <ContactFormContent>
      <div ref={refStep} className="ref_step">{}</div>
      <TransitionSlider isVisible={showCalendly}>
        <Calendly email={calendlyEmail} name={calendlyName} />
      </TransitionSlider>
      <TransitionSlider isVisible={!showCalendly}>
        <form ref={form} onSubmit={submitForm}>
          <InputsContainer>
            <label htmlFor="name">Your name</label>
            <input name="name" type="text" placeholder="Your full name" required ref={name} />
            <label htmlFor="email">Your work email</label>
            <input name="email" type="email" placeholder="name@company.com" required ref={email} />
            <label htmlFor="phone">Phone number</label>
            <input name="phone" type="number" placeholder="+1" required />
            <label htmlFor="company">Your company name</label>
            <input name="company" type="text" placeholder="company name" required />
          </InputsContainer>
          <EmployeesContainer>
            <label className="employees__text">How many employees does your company have?</label>
            <div className="employees_container">
              <div className="employees_container__col_one">
                <label htmlFor="1-10">
                  <input type="radio" name="employees" value="1-10" id="1-10" onChange={validationInputRadio} />
                  <p>1-10</p>
                </label>
                <label htmlFor="11-50">
                  <input type="radio" name="employees" value="11-50" id="11-50" onChange={validationInputRadio} />
                  <p>11-50</p>
                </label>
                <label htmlFor="51-200">
                  <input type="radio" name="employees" value="51-200" id="51-200" onChange={validationInputRadio} />
                  <p>51-200</p>
                </label>
              </div>
              <div className="employees_container__col_two">
                <label htmlFor="201-500">
                  <input type="radio" name="employees" value="201-500" id="201-500" onChange={validationInputRadio} />
                  <p>201-500</p>
                </label>
                <label htmlFor="501-1000">
                  <input type="radio" name="employees" value="501-1000" id="501-1000" onChange={validationInputRadio} />
                  <p>501-1000</p>
                </label>
                <label htmlFor="+1000">
                  <input type="radio" name="employees" value="+1000" id="+1000" onChange={validationInputRadio} />
                  <p>+1000</p>
                </label>
              </div>
            </div>
          </EmployeesContainer>
          <SkillsContainer>
            <div ref={toolTipref} className="tool_tip_ref">{}</div>
            <TransitionOpacity isVisible={radioInputValidation}>
              <ToolTipRequired message="at least one must be selected" />
            </TransitionOpacity>
            <p className="skills__text">Select technologies and skills involved in your company</p>
            <CheckBoxInput
              name="skills"
              data={programingLanguages}
              setskills={setskills}
              skills={skills}
            />
            <TransitionOpacity isVisible={CheckBoxValidation}>
              <ToolTipRequired message="at least one must be selected" />
            </TransitionOpacity>
            <PrivacyPolicy>
              By submitting this form, I agree with
              {' '}
              <a className="privacy_policy" href="https://drive.google.com/file/d/1RabcykHJXuMiL-JvRq3KtCeRt_36PjoY/view?usp=share_link" target="_blank" rel="noopener noreferrer">
                Devstar’s Privacy Policy and Terms and Conditions
              </a>
            </PrivacyPolicy>
            <div className="form_text">
              <button type="submit" className="form_text__button_submit">Continue</button>
              <p className="form_text__p">
                “Software development is both challenging and rewarding. It’s
                creative like an art-form, but (unlike art) provides concrete,
                measurable value
              </p>
              <span className="form_text__span">Chad Fowler</span>
            </div>
          </SkillsContainer>
        </form>
      </TransitionSlider>
    </ContactFormContent>
  );
}

export default FormContact;
