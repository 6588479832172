import { ReportHandler } from 'web-vitals';

interface WebVitals {
  getCLS: Function, // First Input Delay
  getFCP: Function, // Cumulative Layout Shift
  getFID: Function, // First Contentful Paint
  getLCP: Function, // Largest Contentful Paint
  getTTFB: Function, // Time to First Byte
}

const reportWebVitals: Function = (onPerfEntry?: ReportHandler) => {
  if (onPerfEntry && onPerfEntry instanceof Function) {
    import('web-vitals').then(({
      getCLS, getFID, getFCP, getLCP, getTTFB,
    }: WebVitals) => {
      getCLS(onPerfEntry);
      getFID(onPerfEntry);
      getFCP(onPerfEntry);
      getLCP(onPerfEntry);
      getTTFB(onPerfEntry);
    });
  }
};

export default reportWebVitals;
