import styled, { StyledComponent } from 'styled-components';
import colors from '../../../styles/colors';
import device from '../../../styles/deviceSizeScreen';
import stylesFonts from '../../../styles/fonts';

export const SectionOne: StyledComponent<'div', any> = styled.div`

        display:flex;
        flex-direction: row;
        align-items:start;
        font-family:${stylesFonts.regularText};
        height:600px;
        width:100vw;
        overflow: hidden;

        .home_section__one__text{
            display:flex;
            flex-direction: column;
            align-items:start;
            margin: 50px 0px 0px 100px;
            width: 50%;
        }

        .home_section__one__text__animations_container{
            margin-bottom:60px;
        } 

        .home_section__one__text__animations_container h1{
            color: ${colors.greenOne};
            font-size:3.7vh;
            font-family: ${stylesFonts.boldTitles};
            margin:2px;
        }

        .home_section__one__text__animations_container h2{
            color: ${colors.blueOne};
            font-size:6vh;
            font-family: ${stylesFonts.boldTitles};
            margin:3px 0px 2px 0px;
        }

        .home_section__one__text__animations_container p{
            line-height: 1.4;
            font-size:3vh;
            margin:0px;
            text-align:left;
            width:75%;
        }
        
        .Typewriter__cursor{
            font-size:3vh;
            color: ${colors.blueOne};
        }

        .Typewriter__wrapper{
            font-size:3vh;
            color: ${colors.greenOne};
        }

        .home_section__one__text__buttons{
            display:flex;
            justify-content:space-between;
            flex-direction:row;
            width:100%;
        }

        .home_section__one__text__button_one{
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.blueOne};
        border-radius: 30px;
        margin-bottom: 0;
        padding:15px 35px;
        }

        .home_section__one__text__button_one:hover{
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.greenOne};
        }

        .home_section__one__text__button_two{
        color:${colors.black};
        background-color:${colors.white};
        cursor: pointer;
        border: 3px solid black;
        border-radius: 30px;
        padding:15px 35px;
        
        }

        .home_section__one__text__animaitons h3{
            display:flex;
            align-items:center;
            justify-content: space-between;
            color: ${colors.greenOne};
            font-family: ${stylesFonts.boldTitles};
            font-size:20px;
            margin-top: 60px;
            width:29%;
        }

        .home_section__one__img{
            width: 50%;
            position: relative;
        }
        .img__person{
            position:absolute;
            right:220px;
            top:-20px;
            z-index: 10;
        }

        .home_section__one__img__person{
            width: 48vw;
            height:auto;
        }
        .home_section__one__img__shape_blue{
            width: 70vw;
            height:auto;
            position:absolute;
            right:-150px;
            top: -60px;
            z-index: 1;
        }

        .img__lap_top{
            position:absolute;
            right:-220px;
            top: -120px;
            z-index: 10;
        }

        .home_section__one__img__lap_top{
            width: 70vw;
            height:auto;
        }

        .img__spaceship{
            position:absolute;
            right:-120px;
            top: -170px;
            z-index: 15;
        }

        .home_section__one__img__spaceship{
            width: 60vw;
            height:auto;
        }

        .section_one_cards{
            position:absolute;
            right:70px;
            top: 270px;
            z-index: 10;
        }

        .home_section__one__img__cards{
            width: 15vh;
            height: 12vh;
        }

        .img__smoke{
            position:absolute;
            right:57px;
            top: 85px;
            z-index: 10;        
        }
        

        .home_section__one__img__smoke{
            width: 55vh;
            height:auto;
        }

        @media ${device.desktop} {
            height:1000px;

        .img__smoke{
            right:100px;
            top:150px;
        }

        .home_section__one__text__buttons{
            width:100%;
        }
        
        .home_section__one__img__ilustration{
            right:-300px;
            top: 85px;
        }
        
        }

        @media ${device.desktopM} {
        
        .home_section__one__img__ilustration{
            right:-400px;
            top: 85px;
        }

        .img__lap_top{
            right:-320px;
            top: -110px;
        }


        .img__smoke{
            right:40px;
            top: 150px;
        }

        .img__spaceship{
            right:-180px;
            top: -170px;
        }

        .section_one_cards{
            right:50px;
            top: 400px;
        }
 
        }

        @media ${device.desktopL} {

        height:1100px;
        
        .img__person{
            top:-10px;
        }

        .img__lap_top{
            right:-450px;
            top: -110px;
        }

        .img__spaceship{
            right:-300px;
            top: -180px;
        }

        .img__smoke{
            right:0px;
            top: 215px;
        }

        .section_one_cards{
            right:50px;
            top: 550px;
        }

        .home_section__one__text{
            margin: 150px 0px 0px 100px;
        }
  
        .home_section__one__img__ilustration{
            right:-600px;
            top: 180px;
        }
        
        }

        @media ${device.laptopL} {

        height:1200px;
        flex-direction: column;
        align-items:center;
        margin-top: 100px;
        width: 100%;

        .home_section__one__text__animations_container h1, h2{
            text-align: center;
        }

        .home_section__one__text__animations_container p{
            text-align:center;
            width:100%;
        }
        
        .img__lap_top,.img__spaceship,
        .home_section__one__img__shape_blue,
        .section_one_cards,.img__smoke{
            display:none;
        }

        .img__person{
            flex-direction: column;
            align-items:center;
            width: 100% ;
            height: auto;
            position:relative;
            right:0px;
            top:0px;
            z-index: 10;
        }

        .home_section__one__text{
            align-items:center;
            margin: 50px 0px 0px 0px;
            width: 70%;
            
        }
        
        .home_section__one__text__buttons{
            flex-direction: column 
        }

        .home_section__one__text__button_one{
        margin-bottom: 10px;
        }

        .home_section__one__text__animaitons h3{
            margin-top: 30px;
            width:70%;
        }

        .home_section__one__img{
            width: 60% ;
        }
        

        .home_section__one__img__person{
            width: 50vw;
            height:auto;
        }

        }

        @media ${device.desktop32} {   

        .img__smoke{
            right:30px;
            top: 265px;
        }
        }

        @media ${device.laptop} {

        height:1350px;
        .Typewriter{
           text-align:center;
        }

        .home_section__one__text__button_one{
            font-size:3vw;
        }
        
        .home_section__one__img{
            width: 60% ;
        }
        
        .home_section__one__img{
            width: 100vw;
        }

        .home_section__one__img__person{
            width: 100vw;
        }

        }

        @media ${device.tablet} {
            height:1000px;

        .home_section__one__text{
            width: 80%;
        }

        .home_section__one__text__animations_container h2{
            font-size:5.5vh;
        }

        .home_section__one__text__button_one{
            font-size: 3vw;
        }

        }

        @media ${device.mobileXl} {
            height:900px;

        }

        @media ${device.mobileL} {

        height:800px;
        margin-bottom: 100px;

        .home_section__one__text{
            width: 90%;
        }
            
        .home_section__one__text__animations_container h2{
                font-size:4vh;
        }

        }
    
`;

export const SectionTwo: StyledComponent<'div', any> = styled.div`

    font-family:${stylesFonts.regularText};
    display: flex;
    flex-direction: row;
    height:auto;
    margin:150px 0px;

    .home_section__two__img{
        position: relative;
        width: 50%;
    }

    .home_section__two__img__person__container{
        position:absolute;
        left:80px;
        top:30px;
        z-index:10;
    }

    .home_section__two__img__graph{
        width: 400px;
        position:absolute;
        left:120px;
        top:160px;
        z-index: -10;
        border-radius: 10px;
    }

    .shape_blue{
        position: absolute; 
        top:70px; 
        left:20px;    
        width: 80%;
        z-index:-10
    }

    .home_section__two__text{
        display:flex;
        flex-direction: column;
        margin-top:150px;
        padding-left: 30px;
        width: 50%;
    }

    .home_section__two__text h2{
        color: ${colors.blueOne};
        font-family: ${stylesFonts.boldTitles};
        font-size: 3.6vh;
        margin:0;
    }

    .home_section__two__text h3 {
        color: ${colors.greenOne};
        font-family: ${stylesFonts.boldTitles};
        font-size: 5.7vh;
        margin:0;
    }

    .home_section__two__text p {
        text-align:left;
        font-size: 3vh;
        line-height: 1.4;
        margin:5px 0px 15px 0px;
        width: 70%;
    }

    .home_section__two__text ul {
        color:  ${colors.blueOne};
        margin: 0;
        padding-right: 10px;
        padding-left:25px;
    }

    .home_section__two__text li {
        font-size: 2.7vh;
        margin-bottom: 15px;
    }

    @media ${device.desktopM} {

    .home_section__two__img__graph{
        width: 600px;
        top:150px
    }

    }

    @media ${device.desktopL} {

    .home_section__two__img__graph{
        width: 800px;
        top:150px
    }

    }

    @media ${device.laptopL} {

    .home_section__two__text{
        display:flex;
        flex-direction: column;
        margin:150px 0px 0px 150px;
    }

    .home_section__two__img__graph{
       position: static;
       width:70%;
       margin:250px 0px 0px 150px;
    }

    .shape_blue{
        display: none;
    }

    }
    
    @media ${device.laptop} {

    flex-direction:column-reverse;
    align-items:center;
    margin-bottom: 100px;


    .home_section__two__text{
        display:flex;
        flex-direction: column;
        width: 80%;
        margin:0;
        padding: 0;
    }

    .home_section__two__text h2{
        font-size: 3.4vh;
        margin:0;
        width: 100%;
    }

    .home_section__two__text h3 {
        font-size: 5vh;
        width:100%;
    }

    .home_section__two__img{
        position: static;
        display: flex;
        justify-content: center;
        align-items:center;
        width: 100%;
    }

    .home_section__two__img__graph{
       position: static;
       width:70%;
       margin:250px 0px 0px 130px;
    }

    .home_section__two__text p {
        text-align:left;
        font-size: 3vh;
        line-height: 1.4;
        margin:5px 0px 15px 0px;
        width: 100%;
    }

    }

    @media ${device.mobileXl} {

    margin-top:200px;

    .home_section__two__text h3 {
        font-size: 4.5vh;
        width:100%;
    }

    .home_section__two__img{
        width: 90%;
    }

    .home_section__two__img__person{
        width: 120px;
        height:auto;
        top:50px;
        left: 10px;
    }

    .home_section__two__img__graph{
        width: 300px;
        left: 50px;
        margin:100px 0px 0px 7px;
    }

    }
`;

export const SectionThree: StyledComponent<'div', any> = styled.div`

    margin:400px 0px 300px 0px;

    .home_section__three__text{
    display: flex;
    flex-direction: column;
    justify-content:center;
    align-items:center;
    height:auto;
    font-family:${stylesFonts.regularText};
    margin-top:100px;

}

.home_section__three__text h3{
    color: ${colors.greenOne};
    font-family:  ${stylesFonts.boldTitles};
    font-size:5.3vh;
    margin: 0;
}

.home_section__three__text p{
    font-size:3vh;
    line-height: 1.6;
    margin: 3px 0px 35px 0px;
    text-align: center;
    width: 70%;
}

.home_section__three__text span{
    color: ${colors.blueOne};
    font-family:  ${stylesFonts.boldTitles};
}

@media ${device.desktopM} {

    margin:350px 0px 350px 0px;

}

@media ${device.laptop} {

    .home_section__three__text p{
        width: 90%;
    }

    .home_section__three__text h3{
        text-align:center;
    }

}

@media ${device.tablet} {

    margin:150px 0px 300px 0px;

    .home_section__three__text{
        margin-top:200px;
    }

    .home_section__three__text h3{
        font-size:4vh;
        text-align:center;
    }

}

`;

export const SectionFour: StyledComponent<'div', any> = styled.div`

        display:flex;
        flex-direction: row;
        align-items:start;
        font-family:${stylesFonts.regularText};
        margin-top:200px;

    .home_section__four__text{
        display:flex;
        flex-direction: column;
        align-items: start;
        margin: 40px 0px 0px 80px;
        width: 60%;
    }

    .home_section__four__text h2{
        color: ${colors.blueOne};
        font-family: ${stylesFonts.boldTitles};
        font-size: 3.7vh;
        margin-bottom:10px;
    }

    .home_section__four__text h3{
        color: ${colors.greenOne};
        font-family: ${stylesFonts.boldTitles};
        font-size: 6vh;
        margin:0;
    }

    .home_section__four__text p{
        text-align: justify;
        font-size: 3.3vh;
        line-height: 1.4;
        margin-top:5px;
        width:60%;
    }

    .home_section__four__text span{
        color: ${colors.blueOne};
    }

    .home_section__four__img{
        position: relative;
        width: 40%;
    }

    .home_section__four__img__person__container{
        position:absolute;
        right: 60px;
        z-index:10;
    }

    .home_section__four__img__person{
        width: 40vw;
        height: auto;
    }

    .space_ship{
        position:absolute;
        top:270px;
        right: 350px;
        transform: rotate(50deg);
        width:70%;
    }

    .home_section__four__img__spaceship{
        position:absolute;
        right: 45px;
        top:-110px;
        width: 770px;
        z-index: -10;
    }

  
    @media ${device.desktopM} { 

    .space_ship{
        top:350px;
        right: 450px;
    }
    }

    @media ${device.desktopL} { 

        .space_ship{
            top:450px;
            right: 550px;
        }
    }

    @media ${device.desktop} { 
        margin-bottom: 460px;
    }

    @media ${device.laptopL} {
        
    margin-bottom: 300px;

    .home_section__four__text{
        width: 70%;
    }

    .home_section__four__text p{
            text-align:center;
            width:90%;
        }

    .home_section__four__img{
        margin:100px 0px 0px 90px;
        width: 100%;
    }

    .space_ship{
        display: none;
    }
    }

    @media ${device.laptop} {
        margin-bottom:500px;
        flex-direction:column;
        .home_section__four__text{
            margin:0;
            width: 100%;
            align-items: center;
        }

        .home_section__four__text h3{
            font-size:4.7vh;
            margin-bottom: 30px;
        }

        .home_section__four__img{
            margin:100px 0px 0px 0px;
        }

        .home_section__four__img__person__container{
            position:static;
            display: flex;
            justify-content: center;
        }
        
        .home_section__four__img__person{
            position:static;
            width: 82vw;
        }
    }
    
    @media ${device.tablet} {
        margin-bottom:300px;
        flex-direction:column;
        .home_section__four__text{
            margin:0;
            width: 100%;
            align-items: center;
        }

        .home_section__four__text h3{
            color: ${colors.greenOne};
            font-family: ${stylesFonts.boldTitles};
            font-size: 4vh;
            text-align:center;
        }
    }

    @media ${device.mobileXl} {

    .home_section__four__img__person{
        width: 300px;
        }
    }

    @media ${device.mobileL} {
        margin-bottom:300px;

    .home_section__four__img__person{
        width: 300px;
        }
    }

    @media ${device.mobileM} {
        margin-bottom:300px;

    }

`;
