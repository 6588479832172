import styled, { StyledComponent } from 'styled-components';
import stylesFonts from '../../styles/fonts';
import colors from '../../styles/colors';
import device from '../../styles/deviceSizeScreen';

export const ContactFormContent: StyledComponent<'div', any> = styled.div`
    position: relative;

    .ref_step{
        position: absolute;
        top: -210px;
    }

    @media ${device.laptop} {
        margin-top:20px;
    }

    @media ${device.laptop} {
        .refStep{
        position: absolute;
        top: -250px;
    }
    }
`;

export const InputsContainer: StyledComponent<'div', any> = styled.div`
   display:flex;
   flex-direction:column;
   padding:0px 80px 0px 100px;
   margin-top:-10px;
   width: 50%;

   label{
        font-size:2.5vh;
        font-family:  ${stylesFonts.regularText};
        margin-bottom: 5px;
   }

   input {
        border: 1.5px solid #C0C0C0;
        font-family:  ${stylesFonts.regularText};
        font-size:20px;
        padding: 20px;
        margin-bottom:30px;
        width:70%;
    }

    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }   

    @media ${device.laptop} {
        margin-top:50px;
        padding:0px 80px 0px 45px;

    }

    @media ${device.tablet} {
        margin-top:20px;
        padding:0px 30px;
        width:auto;

        input {
        width:auto;
        }
    }

    @media ${device.mobileL} {
        margin-top:20px;
        padding:0px 30px;
    }

    @media ${device.mobileM} {
        margin-top:-20px;
        padding:0px 30px;
    }
   
`;

export const EmployeesContainer: StyledComponent<'div', any> = styled.div`
    padding:0px 80px 0px 100px;

    .employees__text{
        font-size:2.5vh;
        font-family:  ${stylesFonts.regularText};
        margin-bottom: 5px;
    }

    .employees_container{
        display:flex;
    }
    
    .employees_container__col_one,
    .employees_container__col_two {
        display: flex;
        flex-direction: column;
    }

    p{
        color: ${colors.blueOne};
        cursor: pointer;
        border: 1.5px solid #C0C0C0;
        font-family:  ${stylesFonts.regularText};
        font-size:2.5vh;
        margin:0px 20px 20px 0px;
        padding:20px 95px;
    }

    p:hover{
        color: ${colors.white};
        background-color:${colors.blueOne};
        border: 1.5px solid ${colors.blueOne};  
    }

    

    input:checked + p {
        color: ${colors.white};
        background-color:${colors.blueOne};
        border: 1.5px solid ${colors.blueOne}; 
}

    input{
        -webkit-appearance: none;
    }

    @media ${device.laptop} {
        padding:0px 80px 0px 45px;
        
        p{
            padding: 25px 55px;
        }
       
    }

    @media ${device.tablet} {

        p{
            padding: 25px 55px;
            margin:0px 0px 20px 0px;
        }

        display:flex;
        flex-direction: column;
        align-items: center;
        padding:0px 30px;

        .employees_container__col_one,
        .employees_container__col_two {
            width:100%;
        }

        .employees_container{
            display:flex;
            flex-direction:column;
            width:100%;
        }

        label{
            text-align: center;
            margin:0px 0px 20px 0px;
        }

        p{

            margin:0px 0px 20px 0px;
        }
       
    }

    @media ${device.mobileL} {
        padding:0px 30px;
    }

`;

export const SkillsContainer: StyledComponent<'div', any> = styled.div`
    padding:0px 80px 0px 100px;
    position: relative;

    .tool_tip_ref{
        position: absolute;
        top: -210px;
    }

    .skills__text{
        font-family:  ${stylesFonts.regularText};
        font-size:2.5vh;
    }

    .form_text{
        position: relative;
        margin-top:90px;
        width:100%;
    }

    .form_text__button_submit{
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.blueOne};
        border-radius: 30px;
        font-family:  ${stylesFonts.regularText};
        font-size:3vh;
        position: absolute;
        right: 10px;
        width: 230px;
        height:60px;
    }

    .form_text__p{
        color: ${colors.greenOne};
        font-size:3vh;
        font-family:  ${stylesFonts.regularText};
        position: absolute;
        right: 10px;
        top: 70px;
        text-align:right;
        width:60%;
    }

    .form_text__span{
        color: ${colors.blueOne};
        font-size:3vh;
        font-family:  ${stylesFonts.regularText};
        position: absolute;
        right: 10px;
        top: 190px;
    }

    @media ${device.desktop} {
        .form_text__span{
        top: 220px;
    }
    }

    @media ${device.laptopL} {
        padding:0px 80px;   

        .form_text__button_submit{
            font-size:2.5vh;
        }
    }

    @media ${device.laptop} {

    padding:0px 35px;    

    .form_text{
        display:flex;
        flex-direction: column;
        align-items:center;
    }

    .form_text__button_submit{
        position:relative;
        top:-20px;
        right: 0;
    }

    .form_text__p, .form_text__span{
        position:relative;
        top:0;
        right: 0;
    }  
    .form_text__p{
        text-align:center;
        width:100%;
    }
    }

    @media ${device.tablet} {
        padding:0px 20px 0px 15px;
    }

    @media ${device.mobileXl} {
        .skills__text{
            margin-left: 20px;
        }

    }

    @media ${device.mobileL} {
        .skills__text{
           text-align: center;
        }
    }
`;

export const PrivacyPolicy: StyledComponent<'p', any> = styled.p`
        color: ${colors.blueOne};
        font-family:  ${stylesFonts.regularText};

        .privacy_policy{
            color: ${colors.greenOne};
        }

        @media ${device.laptop} {
            margin-left: 20px;
        }
`;
