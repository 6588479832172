import React, { useRef, useEffect } from 'react';
// models
import { MotionProps } from '../../../models/Motion/MotionProps';

function SetViewOnTheTop({ view }:MotionProps):JSX.Element {
  const lookUpView: any = useRef() as React.MutableRefObject<HTMLDivElement>;
  useEffect(() => {
    lookUpView.current?.scrollIntoView(true);
  }, []);
  return (
    <div style={{ position: 'relative' }}>
      <div
        ref={lookUpView}
        style={{
          position: 'absolute',
          top: view,
        }}
      >
        {}
      </div>
    </div>
  );
}

export default SetViewOnTheTop;
