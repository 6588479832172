import emailjs, { EmailJSResponseStatus } from '@emailjs/browser';

function sendEmail(
  serviceId: string,
  templateId: string,
  form: any,
  publicKey: string,
): Promise<EmailJSResponseStatus> {
  return emailjs.sendForm(
    serviceId,
    templateId,
    form,
    publicKey,
  );
}

export default sendEmail;
