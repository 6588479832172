import React from 'react';
import Marquee from 'react-fast-marquee';
// styles
import { Logo, PartnersContainer, MarqueeContainer } from './styles';
// imgs
import beckwith_electrick from '../../assets/images/partners/beckwithElectrick.webp';
import healing_construction from '../../assets/images/partners/HealingConstruction.webp';
import sayer from '../../assets/images/partners/sayer.webp';
import sherpa from '../../assets/images/partners/sherpa.webp';
import nenes from '../../assets/images/partners/nenes.webp';
import conxus from '../../assets/images/partners/conxus.webp';
import bt from '../../assets/images/partners/bt.png';

function Partners():JSX.Element {
  return (
    <PartnersContainer>
      <h3>Our Partners</h3>
      <MarqueeContainer>
        <Marquee gradientWidth={50} speed={80}>
          <Logo src={sherpa} />
          <Logo src={sayer} />
          <Logo src={healing_construction} />
          <Logo src={beckwith_electrick} />
          <Logo src={conxus} />
          <Logo src={nenes} />
          <Logo src={bt} />
        </Marquee>
      </MarqueeContainer>
    </PartnersContainer>
  );
}

export default Partners;
