import React from 'react';
// icons
import { RiFileWarningFill } from 'react-icons/ri';
// styles
import ToolTipContainer from './styles';
// models
import { ToolTipRequiredProps } from '../../models/tooltips/ToolTipRequiredProps';

function ToolTipRequired({ message }:ToolTipRequiredProps):JSX.Element {
  return (
    <ToolTipContainer>
      <div className="tooltiptext">
        <RiFileWarningFill />
        {message}
      </div>
    </ToolTipContainer>
  );
}

export default ToolTipRequired;
