import React from 'react';
// router
import { Link } from 'react-router-dom';
// styles
import { ResponsiveContainer, ResponsiveSubContainer } from './styles';

function FooterResponsive():JSX.Element {
  return (
    <ResponsiveContainer>
      <ResponsiveSubContainer>
        <div className="responsive_sub_container__txt__one">
          <Link to="/home">HOME</Link>
          <Link to="/contact">CONTACT US</Link>
        </div>
        <div className="responsive_sub_container__txt__two">
          <Link to="/careers">CAREERS</Link>
          <Link to="/about_us">ABOUT US</Link>
        </div>
        {/* <div className="responsive_sub_container__txt__three">
          <Link to="/case_study">CASE STUDY</Link>
  </div> */}
      </ResponsiveSubContainer>
    </ResponsiveContainer>
  );
}

export default FooterResponsive;
