import styled, { StyledComponent } from 'styled-components';
import stylesFonts from '../../../styles/fonts';
import colors from '../../../styles/colors';
import device from '../../../styles/deviceSizeScreen';

export const ContactContainer: StyledComponent<'div', any> = styled.div`

    .contact_container__button{
        width: 100%;
        display:flex;
        justify-content:center;
    }

    .contact_container__button__back{
        text-align: center;
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.blueOne};
        border-radius: 30px;
        font-family:  ${stylesFonts.regularText};
        font-size:3vh;
        width: 230px;
        height:60px;
    }

    @media ${device.desktop} {
        margin:150px 0px 850px 0px;
    }

    @media ${device.laptopL} {
        margin:150px 0px 450px 0px;
    }

    @media ${device.laptop} {
        margin:150px 0px 500px 0px;
    }

    @media ${device.tablet} {
        margin-bottom:300px;

        .button__back{
            right: 250px;
        }
    }
`;

export const ContactContent: StyledComponent<'div', any> = styled.div`
   display: flex;
   overflow: hidden;
   height: auto;
`;

export const ContactContentText: StyledComponent<'div', any> = styled.div`
   display: flex;
   flex-direction: column;
   width: 50%;
   padding:50px 0px 0px 100px;
   margin-bottom: 30px;

    h2{
        color: ${colors.greenOne};
        font-size:3.7vh;
        font-family: ${stylesFonts.boldTitles};
        margin:20px 0px;
    }    

    p{
        line-height: 1.4;
        font-family:  ${stylesFonts.regularText};
        font-size:3vh;
        margin:0px 0px 50px 0px;
        text-align:justify;
    }

    .contact_container__step_content{
        display: flex;
        align-items:center;

        img{
            margin-left: 30px;
            width: 30%;
            height: 60%;
        }
    }

    span{
        color: ${colors.greenOne};
        font-size:3vh;
        font-family: ${stylesFonts.regularText};
        margin:2px;
    }

    @media ${device.laptopL} {
        width: 70%;
    }

    @media ${device.laptop} {
        padding:50px 0px 0px 50px;
    }

    @media ${device.tablet} {
        padding:30px;
        width: 100%;

    h2{
        text-align:center;
    }  

    p{
        line-height: 1.4;
        text-align:center;
        margin:0;
    }

    .contact_container__step_content{
       margin-top: 30px;
        img{
            display: none;
        }
    }

    }

    @media ${device.mobileL} {

        padding:30px 20px;

        h3{
            text-align: center;
        }
        
        p{
            width: 100%;
        }


    }

`;

export const ContactContentImg: StyledComponent<'div', any> = styled.div`
   display: flex;
   position: relative;
   width: 50%;


   .contact__img_spaceship{
        position: absolute;
        right: 80px;
        top: 70px;
        width: 450px;
        height:250px;
   }

   .contact__img_arrow{
        position: absolute;
        transform: rotate(25deg);
        top: 50px;
        right: 90px;
        width: 500px;
        height:400px;
        z-index:-10
   }

   @media ${device.desktopM} {

    .contact__img_spaceship{
        right:400px;
   }
 
     .contact__img_arrow{
        display: none;
    }
}

   @media ${device.laptopL} {
 
   .contact__img_spaceship{
        right: 50px;
        top: 130px;
        width: 300px;
        height:150px;
   }

   .contact__img_arrow{
        top: 200px;
        left: 70px;
        width: 300px;
        height:200px;
   }
}

@media ${device.laptop} {
    display:none;
}
`;
