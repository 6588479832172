import styled, { StyledComponent } from 'styled-components';
import device from '../../styles/deviceSizeScreen';
import colors from '../../styles/colors';
import stylesFonts from '../../styles/fonts';

export const PartnersContainer: StyledComponent<'div', any> = styled.div` 
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 250px 0px;

    h3{
        color: ${colors.blueOne};
        font-size:6vh;
        font-family: ${stylesFonts.boldTitles};
        margin:20px 0px;
    }

  
    @media ${device.mobileL} {

    margin:-50px 0px;

    h3{
        font-size:4vh;
    }

    }
`;

export const MarqueeContainer: StyledComponent<'div', any> = styled.div`
    width:90%;

    @media ${device.desktopM} {
        width:80%;
    }

    @media ${device.desktopL} {
        width:60%;
    }

    @media ${device.mobileL} {
        width:100%;
    }

`;

export const Logo: StyledComponent<'img', any> = styled.img`
    margin: 0px 50px;
    width: 300px;
    height: auto;

    @media ${device.mobileXl} {
        margin: 0px 20px;
        width: 150px;
        height: auto;
    }

`;
