const size: Record<string, string> = {
  desktop: '1500px',
  desktop32: '2560px',
  desktopL: '2200px',
  desktopM: '1800px',
  desktopS: '1500px',
  laptop: '1024px',
  laptopL: '1340px',
  laptopM: '1224px',
  mobileL: '425px',
  mobileM: '400px',
  mobileS: '320px',
  mobileXl: '500px',
  tablet: '768px',
  tabletL: '900px',
};

const device: Record<string, string> = {
  desktop: `(min-width: ${size.desktop})`,
  desktop32: `(min-width: ${size.desktop32})`,
  desktopL: `(min-width: ${size.desktopL})`,
  desktopM: `(min-width: ${size.desktopM})`,
  desktopS: `(min-width: ${size.desktopS})`,
  laptop: `(max-width: ${size.laptop})`,
  laptopL: `(max-width: ${size.laptopL})`,
  laptopM: `(max-width: ${size.laptopM})`,
  mobileL: `(max-width: ${size.mobileL})`,
  mobileM: `(max-width: ${size.mobileM})`,
  mobileS: `(max-width: ${size.mobileS})`,
  mobileXl: `(max-width: ${size.mobileXl})`,
  tablet: `(max-width: ${size.tablet})`,
  tabletL: `(max-width: ${size.tabletL})`,
};

export default device;
