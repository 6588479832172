import React from 'react';
// img
import in_process from '../../../assets/images/in_process.png';
// styles
import PartnershipsContainer from './styles';

function Partnerships():JSX.Element {
  return (
    <PartnershipsContainer>
      <img src={in_process} alt="" />
    </PartnershipsContainer>
  );
}

export default Partnerships;
