import React, { useState, useEffect } from 'react';
// bootstrap
import Modal from 'react-bootstrap/Modal';
// styles
import ButtonCv from './styles';
// env
import { env } from '../../env';

function LoadCvModal() {
  const [show, setShow] = useState(false);
  useEffect(() => {
    const script:HTMLScriptElement = document.createElement('script');

    script.src = 'https://apps.elfsight.com/p/platform.js';

    script.async = true;

    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <ButtonCv type="button" onClick={() => setShow(true)}>
        SEND CV
      </ButtonCv>

      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName="modal-90w"
        aria-labelledby="example-custom-modal-styling-title"
      >
        <Modal.Header closeButton />
        <Modal.Body>
          <div className={env.REACT_APP_DEVSTAR_TEMPLATE_ELFSIGHT} />
        </Modal.Body>
      </Modal>
    </>
  );
}

export default LoadCvModal;
