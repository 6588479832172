import styled, { StyledComponent } from 'styled-components';

export const CalendlyContainer: StyledComponent<'div', any> = styled.div`
    width: 100%;
    .calendly_container_button{
        width:100%;
        display:flex;
        justify-content:center;
    }
`;

export const CalendlyElement: StyledComponent<'div', any> = styled.div`
    width: 100%;
`;
