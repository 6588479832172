import React from 'react';
// components
import ResponsiveContainer from '../footerResponsive';
// styles
import { FooterContainer, FoooterSubContainer } from './styles';
// img
import footer_logo from '../../assets/images/white_logo_transparent_background.webp';

function Footer():JSX.Element {
  return (
    <FooterContainer>
      <FoooterSubContainer>
        <img src={footer_logo} alt="logo Devstar footer" />
        <ResponsiveContainer />
        <div className="footer__contact">
          <p>contact@devstar.mx</p>
          <p>+52 55 2623 1687</p>
        </div>
        <p className="footer__copyrigh">© 2022 All Rights Reserved | Devstar</p>
      </FoooterSubContainer>
    </FooterContainer>
  );
}

export default Footer;
