import styled, { StyledComponent } from 'styled-components';
import device from '../../styles/deviceSizeScreen';
import colors from '../../styles/colors';
import stylesFonts from '../../styles/fonts';

export const ResponsiveContainer: StyledComponent<'div', any> = styled.div`
    
    display: none;
    
    @media ${device.laptopL} {
        display: flex;
        flex-direction: column;
        font-family: ${stylesFonts.regularText};
        width: 100%;
        height:auto;
        margin-top:30px;
    }    
`;

export const ResponsiveSubContainer: StyledComponent<'div', any> = styled.div` 
    
    @media ${device.laptopL} {
        color:${colors.white};
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        width:100%;
        height:60px;

        .responsive_sub_container__txt__one,
        .responsive_sub_container__txt__two,
        .responsive_sub_container__txt__three{
            margin-top:10px;
        }

        .responsive_sub_container__txt__one a,
        .responsive_sub_container__txt__two a,
        .responsive_sub_container__txt__three a{
            margin:0px 30px;
            text-align:justify;
        }
    }

    @media ${device.mobileL} {
    } 

`;
