import React, { useState } from 'react';
// bootstrap
import Modal from 'react-bootstrap/Modal';
// styles
import {
  CardContainer,
  ModalTitle,
  ModalSubTitle,
  ModalImage,
  ModalDescription,
  ModalParagraph,
} from './styles';
import colors from '../../styles/colors';
// props
import { CardsProps } from '../../models/cards/CardsProps';
import LeftToRight from '../animations/transitionLeftToRight';
import OpacityIntro from '../animations/opacityIntro';

interface Person {
  background?: string;
  description?: string;
  img?: string;
  name? : string;
}

function Cards({ data }:CardsProps):JSX.Element {
  const [show, setShow] = useState(false);
  const [person, setPerson] = useState<Array<Person>>([]);
  const getPersonByName:
  React.MouseEventHandler<HTMLButtonElement | undefined > = (name: any) => {
    const filter:any = data.filter((i: any) => i.name === name);
    setPerson(filter);
    setShow(true);
  };

  return (
    <CardContainer>
      {data.map((item: any) => (
        <div key={item.name + 1}>
          <div className="card" key={item.name}>
            <img src={item.img} alt={item.name} className="card_img" />
            <h2 className="card_title">{item.name}</h2>
            <p className="card_text">{item.description}</p>
            <button type="button" className="card_button" onClick={() => getPersonByName(item.name)}>Learn more</button>
          </div>
          {person.length > 0 && (
          <Modal
            scrollable
            show={show}
            onHide={() => setShow(false)}
            size="lg"
            key={item.img}
          >
            <Modal.Header closeButton style={{ background: `${colors.blueOne}` }} />
            <Modal.Body className="px-3">
              <OpacityIntro timeDelay={0.2}>
                <div className="w-100 d-flex justify-content-center justify-content-xl-start px-1">
                  <div className="d-flex flex-column justify-content-start">
                    <ModalImage src={person[0]?.img} alt={item.name} />
                    <ModalTitle>{person[0]?.name}</ModalTitle>
                  </div>
                </div>
              </OpacityIntro>
              <LeftToRight timeDelay={0.1}>
                <ModalDescription className="px-1">{person[0]?.description}</ModalDescription>
                <ModalSubTitle className="px-1">Background</ModalSubTitle>
                <ModalParagraph className="pt-2 px-1">
                  {person[0]?.background}
                </ModalParagraph>
              </LeftToRight>
            </Modal.Body>
          </Modal>
          )}
        </div>
      ))}
    </CardContainer>
  );
}

export default Cards;
