import React from 'react';
// icons
import { FaLinkedinIn } from 'react-icons/fa';
// router
import { NavLink } from 'react-router-dom';
// styles
import { NavContainer, HeaderContainer, UlContainer } from './styles';
// assets
import logo from '../../assets/images/logo_transparent_background.webp';
// models
import { NavLinkProps } from '../../models/reactRouter/NavLinkProps';
// components
import MenuResponsive from '../MenuResponsive';

function Header():JSX.Element {
  return (
    <NavContainer>
      <HeaderContainer>
        <img src={logo} alt="Devstar logo" />
        <MenuResponsive />
        <UlContainer>
          <li className="header__menu__options">
            <NavLink
              to="/home"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
              aria-label="HOME"
            >
              HOME
            </NavLink>
          </li>
          <li className="header__menu__options">
            <NavLink
              to="/about_us"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
              aria-label="ABOUT US"
            >
              ABOUT US
            </NavLink>
          </li>
          <li className="header__menu__options">
            <NavLink
              to="/careers"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
              aria-label="CAREERS"
            >
              CAREERS
            </NavLink>
          </li>
          <li className="header__menu__options">
            <NavLink
              to="/contact"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
              aria-label="CONTACT US"
            >
              CONTACT US
            </NavLink>
          </li>
          {/* <li className="header__menu__options">
            <NavLink
              to="/Case_study"
              className={({ isActive }:NavLinkProps) => (isActive ? 'active' : undefined)}
            >
              CASE STUDY
            </NavLink>
          </li>
          < className="header__icons">
            {/* <div className="header__icons__bg__search">
               <FaSearch className="header__icons__search" />
            </div> */}
          <div className="header__icons__bg__linkedin">
            <div className="header__icons__linkedin">
              <a
                href="https://www.linkedin.com/company/devstarmx/mycompany/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LINKEDIN"
              >
                <FaLinkedinIn />
              </a>
            </div>
          </div>
        </UlContainer>
      </HeaderContainer>
    </NavContainer>
  );
}

export default Header;
