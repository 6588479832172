import react from '../assets/images/programming_languages/react.png';
import node from '../assets/images/programming_languages/node.png';
import python from '../assets/images/programming_languages/python.png';
import php from '../assets/images/programming_languages/php.png';
import ruby from '../assets/images/programming_languages/ruby.png';
import swift from '../assets/images/programming_languages/swift.png';
import android from '../assets/images/programming_languages/android.png';
import go from '../assets/images/programming_languages/go.svg';
import java from '../assets/images/programming_languages/java.png';
import angular from '../assets/images/programming_languages/angular.png';
import data from '../assets/images/programming_languages/data_base.png';
import other from '../assets/images/programming_languages/code.png';

const programmingLanguages:Array<object> = [
  {
    img: react,
    name: 'React',
  },
  {
    img: node,
    name: 'NodeJs',
  },
  {
    img: python,
    name: 'Python',
  },
  {
    img: php,
    name: 'Php',
  },
  {
    img: ruby,
    name: 'Ruby',
  },
  {
    img: swift,
    name: 'Swift',
  },
  {
    img: android,
    name: 'Android',
  },
  {
    img: go,
    name: 'Go',
  },
  {
    img: java,
    name: 'Java',
  },
  {
    img: angular,
    name: 'Angular',
  },
  {
    img: data,
    name: 'Data Enginer',
  },
  {
    img: other,
    name: 'Other',
  },
];

export default programmingLanguages;
