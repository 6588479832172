import styled, { StyledComponent } from 'styled-components';
import device from '../../../styles/deviceSizeScreen';
import colors from '../../../styles/colors';
import stylesFonts from '../../../styles/fonts';

export const CaseStudyContainer: StyledComponent<'div', any> = styled.div`
    display: grid;
    grid-template-columns: repeat( 3, minmax(22vw, 1fr) );
    margin:10px 0px 250px 0px;
    overflow-y: scroll;
    overflow-x: hidden;
    padding: 30px 30px 30px 100px;
    width:100%;
    height: 100vh;

    @media ${device.desktopL}{
        margin-bottom: 500px;
    }

    @media ${device.laptopL}{
        margin-top: 100px;
        padding: 20px;
    }

    @media ${device.laptop}{
        grid-template-columns: repeat( 2, minmax(22vw, 1fr) );
        margin-top: 150px;
        padding:0px 40px;
    }

    @media ${device.mobileXl}{
        grid-template-columns: repeat( 1, minmax(22vw, 1fr) );
        margin-top: 150px;
        padding: 0px 65px;
    }
`;

export const Card: StyledComponent<'div', any> = styled.div`
        background-color: ${colors.white};
        border-radius: 6px;
        box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
        margin:10px;
        width: fit-content;
        height: fit-content;
        transition: 0.3s;

    :hover {
        box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
    }

    img {
        border-radius: 6px 6px 0px 0px;
        max-width:22vw;
        padding:0;
        height: auto;
    }

    .card_text_container{
        max-width: 250px;
        margin: 20px;
    }

    h2 {
        color: ${colors.black};
        font-size:2.5vh;
        font-family: ${stylesFonts.boldTitles}; 
        margin-bottom:20px;
    }

    .card_text {
        color: ${colors.black};
        font-size:1.7vh;
        font-family: ${stylesFonts.regularText}; 
    }


    .btn_card{
        color:${colors.white};
        cursor: pointer;
        border:none;
        background-color: ${colors.greenOne};
        border-radius: 1px;
        font-family:  ${stylesFonts.regularText};
        font-size:2.0vh;
        padding: 8px;

    }

    .custom_modal_header{
        background-color:${colors.blueOne}
    }

    @media ${device.desktop32} { 
        .card_text_container{
            max-width: 500px;
        }
    }

    @media ${device.desktopM} { 
        .card_text_container{
            max-width: 350px;
        }
    }
    
    @media ${device.laptopL} { 
        .card_text {
            font-size:1.2vh;
        }

        img {
            max-width:28vw;
        }
    }

    @media ${device.laptop} { 
        img {
        max-width:33vw;
        }

        .card_text {
            font-size:1.2vh;
        }
    }

    @media ${device.tabletL} { 

        img {
            max-width:40vw;
        }
        .card_text_container{
            max-width: 200px;
        }
    }
    
    @media ${device.mobileXl} { 

        img {
            max-width:65vw;
        }

    }


`;
