import sherpa from '../assets/images/case_study/sherpa_case_study.webp';

const caseStudy: Array<object> = [
  {
    description: 'discover how devstar`s development team helped solve Sherpa`s needs in an agile and successful way.',
    img: sherpa,
    link: 'https://drive.google.com/file/d/12tvspTX0JNiWvavBVXR0yklfDhXg8iin/view?usp=sharing',
    name: 'Sherpa',
  },
];

export default caseStudy;
