import francisco from '../assets/images/about_us/francisco.webp';
import santiago from '../assets/images/about_us/santiago.webp';
import jp from '../assets/images/about_us/jp.webp';

const leads: Array<object> = [
  {
    background: 'Francisco is a visionary entrepreneur who has dedicated his career to improving the working conditions of Mexicans and promoting their talents on the global stage. As the CEO of Devstar, he is leading the charge managing a company that exemplifies the best of Mexican ingenuity, innovation, and hard work. With a background in wealth management, Francisco has a keen eye for investment opportunities and a deep understanding of the market. He has used this knowledge to create successful businesses in North America in a range of industries and now technology. Francisco`s ability to spot trends and capitalize on emerging markets has been instrumental in the growth of his companies. Francisco believes that technology and human knowledge can coexist in the same ecosystem, each complementing the other, and that a company`s success is ultimately dependent on its employees. As such, he places a strong emphasis on creating the best working conditions for his team, providing them with the tools and resources they need to achieve their full potential. He understands the importance of collaboration and teamwork in achieving success and is always looking for ways to integrate Devstar into the wider community. Whether it`s through joining associations, seeking strategic partnerships, or participating in community initiatives, Francisco is committed to creating lasting connections that benefit both Devstar and its stakeholders.',
    description: 'Co-Founder and Chief Executive Officer.',
    img: francisco,
    name: 'Francisco J. Mijares',
  },
  {
    background: 'Santiago`s journey in the business world started in the packaging industry, where he worked as a sales representative. He quickly developed an interest in entrepreneurship and has been involved in developing and growing companies in various industries, including medical, chemical, construction materials and tech. With his background in engineering and sales, Santiago is an expert in identifying market opportunities and developing sales strategies that can drive revenue growth. In his current role, he collaborates closely with clients and business partners to facilitate solution integrations helping the company expand market share across all tech settings. He believes these partnerships are integral to improving operator performance, the staff work experience, and the daily services delivered to tech executives. He also dedicates time to employee advancement, mentoring and leading others to help them achieve their professional goals.',
    description: 'Co-Founder and Chief Commercial Officer.',
    img: santiago,
    name: 'Santiago Barcón',
  },
  {
    background: 'As COO, Juan Pablo oversees the company’s tech operations and client-facing organizations including recruitment, performance management, software engineering, client success, implementation, and Staff Management. He believes that excellent people management and the correct implementation of engineering best practices is the key to enhancing performance. Juan Pablo also helps clients increase their software engineering reach by advising them on the implementation performance measurement of software teams, so they can transform employee experience into actionable insights. Juan Pablo was born in Mexico and lived and studied in the US and UK. He graduated as an Industrial and Systems Engineer from Tec de Monterrey University, Mexico City. He brings technology expertise, in areas including tech operations, tech management, e-commerce, and business intelligence technologies. During his previous roles, he helped scale operations through the design and implementation of process and controls for the company’s proprietary operating software platform – focused on efficiency at the user level and driving results at the portfolio level. In his free time Juan Pablo enjoys playing soccer, doing triathlons, reading, traveling and spending time with his family.',
    description: 'Co-Founder and Chief Operating Officer.',
    img: jp,
    name: 'Juan Pablo Vera',
  },
];

export default leads;
