import styled, { StyledComponent } from 'styled-components';

const ToolTipContainer: StyledComponent<'div', any> = styled.div`
  position: relative;
  display: inline-block;
  margin-bottom:10px;

  .tooltiptext {
  width: 280px;
  background-color: white;
  border: 1px solid #0E6655;
  color: black;
  text-align: center;
  border-radius: 6px;
  box-shadow: 8px 7px 11px -3px rgba(0,0,0,0.41);
  padding: 10px 0;
  position: absolute;
  transition: opacity 5s;  
  margin: -10px 0px 20px -10px;
}
.tooltiptext::after {
    content: "";
    position: absolute;
    color: #ED3E44;
    font-size: 24px;
    font-weight: bold;
    transform: rotate(45deg);
    top: -8px;
    left: 35px;
    width: 15px;
    height: 15px;
    border: 1px solid #0E6655;
    background: #fff;
    border-radius: 2px;
    border-right:none;
    border-bottom:none;
}
svg{
    color:#F4D03F;
    width:20px;
    height:20px;
    position: absolute;
    top:11px;
    left:10px;
}
`;

export default ToolTipContainer;
