import React, { useState, useEffect } from 'react';
// components
import FormContactComponent from '../../formContact';
import SetViewOnTheTop from '../../animations/setViewOnTheTop';
// img
// import spaceship from '../../../assets/images/contact_page/spaceship-min.png';
// import arrow_up from '../../../assets/images/contact_page/arrow_up-min.png';
// import arrow from '../../../assets/images/contact_page/arrow-min.png';
// styles
import {
  ContactContainer, ContactContent, ContactContentText,
} from './styles';

// animations
import OpacityIntro from '../../animations/opacityIntro';
import LeftToRight from '../../animations/transitionLeftToRight';

function Contact():JSX.Element {
  const [step, setStep] = useState<number>(1);
  useEffect(() => {
    document.title = 'Devstar | Contact';
    return () => {
      document.title = ' ';
    };
  }, []);
  return (
    <ContactContainer>
      <SetViewOnTheTop view="-100px" />
      <ContactContent>
        <LeftToRight timeDelay={0.5}>
          <ContactContentText>
            <h2>Why partner with Devstar?</h2>
            <p>
              Partnering with us will add experience and knowledge to your team.
              We ensure that we understand your goals,
              expectations, and definition of success while regularly reviewing
              performance against expectations. We’re strategic,
              specific, and practical, and we love what we do.
            </p>
            <div className="contact_container__step_content">
              <span>
                STEP
                {' '}
                {step}
                {' '}
                OF 2
              </span>
              {/* <img src={arrow} alt="" className="contact__img_person" /> */}
            </div>
          </ContactContentText>
        </LeftToRight>
        <OpacityIntro timeDelay={1.2}>
          {/* <ContactContentImg>
             <img src={spaceship} alt="" className="contact__img_spaceship" />
            <img src={arrow_up} alt="" className="contact__img_arrow" />
          </ContactContentImg> */}
        </OpacityIntro>
      </ContactContent>
      <LeftToRight timeDelay={0.5}>
        <FormContactComponent step={step} setStep={setStep} />
      </LeftToRight>
    </ContactContainer>
  );
}

export default Contact;
