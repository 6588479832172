import styled, { StyledComponent } from 'styled-components';
import device from '../../../styles/deviceSizeScreen';
import colors from '../../../styles/colors';
import stylesFonts from '../../../styles/fonts';

export const AboutUsContainer: StyledComponent<'div', any> = styled.div`
    overflow: hidden;
`;

export const TitleS: StyledComponent<'h2', any> = styled.h2`
    
        color: ${colors.greenOne};
        font-size:3.7vh;
        font-family: ${stylesFonts.boldTitles};  

        @media ${device.laptopM} {
        text-align:center;
        margin-top:120px;
    }
        
        @media ${device.laptop} {
        text-align:center;
        margin-top:100px;
    }
`;

export const SubTitleM: StyledComponent<'h3', any> = styled.h3`
    
        color: ${colors.blueOne};
        font-size:5vh;
        font-family: ${stylesFonts.boldTitles};

        @media ${device.laptop} {
        text-align:center;
    }
`;

export const Paragraph: StyledComponent<'p', any> = styled.p`
    
        line-height: 1.4;
        font-size:2.7vh;
        text-align:left;
        width: 80%;
        @media ${device.laptop} {
        text-align:center;
        width: 98%;

    }
`;

export const SectionTwo: StyledComponent<'div', any> = styled.div`
    background-color:${colors.blueOne};
    display: flex;
    flex-direction: column;
    width:100%;
    height: auto;
    margin-top: 200px;
    padding-top:70px;

    .sectionTwoTitle{
        color: ${colors.greenOne};
        font-size:5vh;
        font-family: ${stylesFonts.boldTitles};
        margin-bottom:30px;
        padding: 0px 20px;
        text-align:center;
    }
`;

export const CardContainer: StyledComponent<'div', any> = styled.div`
    
  display: flex;
  justify-content: space-around;
  padding:0px 40px;
  overflow-x: scroll;
  width: 100%;
  flex-wrap: nowrap;

    .card {
        background-color: ${colors.white};
        border-radius: 6px;
        flex-shrink: 0;
        margin-bottom: 50px;
        width: auto;
        height: auto;
        margin-right: 10px;
    }

    .card_img {
        border-radius: 6px 6px 0px 0px;
        max-width: 20vw;
        height: auto;
    }

    .card_title {
        color: ${colors.black};
        font-size:2.5vh;
        font-family: ${stylesFonts.boldTitles}; 
        margin: 20px 10px 0px 10px;
    }

    .card_text {
        color: ${colors.black};
        font-size:1.7vh;
        font-family: ${stylesFonts.regularText}; 
        margin: 10px 0px 10px 10px;
    }

    .card_button{
        background: none;
        color: ${colors.greenOne};
        border: none;
        margin: 0px 10px 20px 10px;
        padding: 0;
        width: 100px;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        font-size:2.0vh;
    }


    @media ${device.laptopL} { 
        justify-content:space-between;

        .card{
            margin:0px 20px 20px 20px;
        }

        .card_img {
            max-width: 300px;
        }

        .card_text {
            font-size:1.5vh;
    }
    }
`;

export const CarrouselContainer: StyledComponent<'div', any> = styled.div`
    margin: 300px 0px 100px 0px;
    .carrousel_text{
        color: ${colors.blueOne};
        font-size:5vh;
        font-family: ${stylesFonts.boldTitles};
        margin-bottom:30px;
        padding: 0px 20px;
        text-align:center;
    }
    
    @media ${device.desktop} { 
        margin:500px 0px 1400px 0px;
    }

    @media ${device.laptopL} { 
        margin-top:200px;
    }
`;

export const ImgCarousel: StyledComponent<'img', any> = styled.img`
    width: 100%;
    height:auto;
    padding: 0px 30px;
`;

export const SectionFour: StyledComponent<'div', any> = styled.div`
    // background-color:${colors.blueOne};
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 200px;
    width:100%;    
    .sectionFourTitle{
        color: ${colors.greenOne};
        font-size:6vh;
        font-family: ${stylesFonts.boldTitles};
        margin:120px 0px 30px 0px;
        text-align:center;
    }

    button{
        color:#ffff;
        cursor: pointer;
        border: none;
        background-color: ${colors.blueOne};
        border-radius: 30px;
        font-family:  ${stylesFonts.regularText};
        font-size:3vh;
        width: auto;
        padding: 20px 30px;
        margin-bottom: 20px;
    }

   
    @media ${device.desktopS} { 
        height: 1340px;
        margin-top:-1100px;
    }

    @media ${device.laptopL} { 
            height: 900px;

    }

    @media ${device.tabletL} { 
            padding: 0px 20px;
            margin-bottom: 50px;
    }
`;

export const MissionContainer: StyledComponent<'div', any> = styled.div`
    margin: 300px 0px 350px 0px;

    .mission_title{
        color: ${colors.greenOne};
        font-size:4vh;
        font-family: ${stylesFonts.boldTitles};  
        text-align: center;
        width: 100%;
    }

    .mission_text{
        color: ${colors.blueOne};
        font-size:4.5vh;
        font-family: ${stylesFonts.boldTitles};
        text-align: center;
        width: 100%;
    }

    @media ${device.desktopS} { 
        margin:500px 550px;
    }

    @media ${device.desktopL} { 
        margin:600px 650px;
    }

`;
